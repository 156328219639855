import { Upload } from 'components/render/input/upload'

interface Props {
  children: React.ReactNode
  withOptions?: boolean
}

export const Layout: React.FC<Props> = ({ children, withOptions }) => {
  return (
    <div className="flex justify-between overflow-hidden h-screen">
      <div className="bg-gray-100 w-full">
        <div className="p-[10px] absolute left-0">
          <img className="w-40 mix-blend-darken opacity-70" alt="Logo" src="/logo.png" />
        </div>

        <Upload withOptions={withOptions} />
        {children}
      </div>
    </div>
  )
}

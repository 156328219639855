// @ts-nocheck
import { useGetAllProductsByMenuQuery } from 'api/graphql/types.generated'
import { useGraphQLClient } from 'providers/hooks'
import { useParams } from 'react-router-dom'

export function useGetAllProductsByMenu(password: string | null) {
  const { graphQLClient } = useGraphQLClient()
  const { slug } = useParams<{ slug: string }>()

  const queryInfo = useGetAllProductsByMenuQuery(
    graphQLClient,
    {
      slug: slug!,
      password: password!
    },
    {
      enabled: Boolean(slug),
      initialData: {},
      suspense: false,
      useErrorBoundary: true
    }
  )

  return {
    data: queryInfo.data?.getAllProductsByMenu,
    errors: queryInfo.error?.response?.errors.map((e) => e.message),
    isError: queryInfo.isError,
    productsIsFetched: queryInfo.isFetched,
    dataUpdatedAt: queryInfo.dataUpdatedAt,
    refetch: queryInfo.refetch
  }
}

// @ts-nocheck
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { Menu } from 'type'

interface Props {
  data: Menu
  color?: string
}

export const Footer: React.FC<Props> = ({ data, color }) => {
  const styles = StyleSheet.create({
    section: {
      margin: 10,
      position: 'relative',
      top: '0px',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '1px',
      borderTop: `2px solid ${color}`,
      textAlign: 'center',
      fontFamily: 'saa',
      fontSize: '6.5pt',
      textTransform: 'uppercase',
      fontWeight: 'bold'
    },
    flex: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginTop: '3px',
      fontSize: '7.5pt'
    },
    line: {
      borderTop: `1px solid ${color}`,
      width: '100%',
      marginTop: '1px'
    },
    title: {
      margin: '10px 0 3px 0'
    },
    adress: {
      margin: '3px 0'
    },
    description: {
      margin: '3px 0'
    }
  })
  return (
    <>
      <View style={styles.section}>
        <View style={styles.line} />
        <Text style={styles.title}>{data.team}</Text>
        <Text style={styles.adress}>{data.specificities}</Text>
        <Text style={styles.description}>{data.allergens}</Text>
      </View>
    </>
  )
}

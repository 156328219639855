import { FilterModalButton } from './FilterModalButton'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

type FiltersPropsType = {
  section1Title: string
  section2Title: string
  backgroundColor?: string | null
  marketingTitles: string[]
  titles: string[]
  onClose: () => void
  onFilter: ({ marketingTitles, titles }: { marketingTitles: string[]; titles: string[] }) => void
  activatedMarketingTitles: string[]
  activatedTitles: string[]
  parentId: string
  subCategoryTitle?: string
}

export const FilterModal = ({
  backgroundColor,
  marketingTitles,
  titles,
  onClose,
  activatedMarketingTitles,
  activatedTitles,
  onFilter,
  section1Title,
  section2Title,
  parentId,
  subCategoryTitle
}: FiltersPropsType) => {
  const [marginTop, setMarginTop] = useState(0)
  const { t } = useTranslation()

  const [isParentHeaderSticky, setIsParentHeaderSticky] = useState(false)
  const thereAreActivatedFilters = activatedTitles.length > 0 || activatedMarketingTitles.length > 0

  const activateFilter = (title: string, isMarketing: boolean) => {
    if (isMarketing) {
      if (!activatedMarketingTitles.includes(title)) {
        onFilter({
          marketingTitles: [...activatedMarketingTitles, title],
          titles: activatedTitles
        })
      } else {
        onFilter({
          marketingTitles: activatedMarketingTitles.filter((marketingTitle) => marketingTitle !== title),
          titles: activatedTitles
        })
      }
    } else {
      if (!activatedTitles.includes(title)) {
        onFilter({
          marketingTitles: activatedMarketingTitles,
          titles: [...activatedTitles, title]
        })
      } else {
        onFilter({
          marketingTitles: activatedMarketingTitles,
          titles: activatedTitles.filter((tag) => tag !== title)
        })
      }
    }
  }

  const handleResetFilter = () => {
    onFilter({
      marketingTitles: [],
      titles: []
    })
  }

  useEffect(() => {
    const container = document.querySelector('#main')

    if (!container || !parentId) {
      return
    }

    const element = document.getElementById(parentId)

    if (element) {
      const rect = element.getBoundingClientRect()

      if (rect.top < 93.5) {
        setIsParentHeaderSticky(true)
      } else {
        setIsParentHeaderSticky(false)
      }
    }
  }, [])

  useEffect(() => {
    const stickyInfoElement = document.getElementById('sticky-info')
    const stickyHeaderElement = document.getElementById('sticky-header')

    let totalMargin = 0

    if (stickyInfoElement) {
      totalMargin += stickyInfoElement.clientHeight
      stickyInfoElement.scrollIntoView(true)
    }

    if (stickyHeaderElement) {
      totalMargin += stickyHeaderElement.clientHeight
    }

    setMarginTop(totalMargin)
  }, [isParentHeaderSticky])

  return (
    <div
      style={{ backgroundColor: backgroundColor ?? '', height: `calc(100vh - ${marginTop}px)` }}
      className={`fixed left-auto z-50 bottom-0 w-full flex flex-col max-w-[768px]`}
    >
      {subCategoryTitle && (
        <div className="text-center font-saaFD">
          <div
            style={{ backgroundColor: backgroundColor ?? '' }}
            className="border-primary py-[10px] border-y relative bg-white -mb-[1px] w-full"
          >
            <p className="text-[17px] uppercase">{subCategoryTitle}</p>
          </div>
        </div>
      )}
      <div
        style={{ backgroundColor: backgroundColor ?? '' }}
        className="bg-primary text-white flex flex-col p-[14px] h-full overflow-auto"
      >
        <div className="flex justify-between items-center mb-3 p-2">
          <button onClick={onClose}>
            <img src="/assets/icons/cross.svg" alt="close" />
          </button>
          <button
            className="flex items-center gap-2"
            onClick={thereAreActivatedFilters ? handleResetFilter : undefined}
          >
            {thereAreActivatedFilters ? (
              <>
                <img className="h-[12px] w-[12px]" src="/assets/icons/cross.svg" alt="close" />
                <p className="text-[13px] font-saaFD">{t('resetFilters')}</p>
              </>
            ) : (
              <>
                <img src="/assets/icons/filters.svg" alt="filters" />
                <p className="text-[13px] font-saaFD">{t('filters')}</p>
              </>
            )}
          </button>
        </div>
        <p className="font-prestige text-[13px]">{section1Title}</p>
        <div className="grid grid-cols-2 px-10 gap-3 py-4">
          {titles.map((title) => (
            <FilterModalButton
              key={title}
              onClick={() => activateFilter(title, false)}
              isActive={activatedTitles.includes(title)}
              title={title}
            />
          ))}
        </div>

        <p className="mt-2 font-prestige text-[13px]">{section2Title}</p>
        <div className="grid grid-cols-1 px-10 gap-3 py-4">
          {marketingTitles.map((marketingTitle) => (
            <FilterModalButton
              key={marketingTitle}
              onClick={() => activateFilter(marketingTitle, true)}
              isActive={activatedMarketingTitles.includes(marketingTitle)}
              title={marketingTitle}
            />
          ))}
        </div>

        {thereAreActivatedFilters && (
          <button onClick={onClose} className="underline font-prestige text-[13px] mt-2">
            Show the results
          </button>
        )}
      </div>
    </div>
  )
}

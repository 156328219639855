// @ts-nocheck
import { Page, View, Document, StyleSheet, Font, Text } from '@react-pdf/renderer'
import { Header } from './header'
import { Menu } from 'type'
import { Bcorp } from './bcorp'
import { Products } from './products'
import { Footer } from './footer'
import { Birra } from './birra'

// @ts-ignore: Unreachable code error
import saa from 'fonts/saa.ttf'
// @ts-ignore: Unreachable code error
import prestigeBold from 'fonts/p-bold.ttf'
// @ts-ignore: Unreachable code error
import prestige from 'fonts/prestige.ttf'
// @ts-ignore: Unreachable code error
import times from 'fonts/times.woff'

import { PricePhilosohpy } from './pricePhilosohpy'

Font.register({
  family: 'saa',
  format: 'truetype',
  fonts: [{ src: saa }]
})
Font.register({
  family: 'prestige-bold',
  format: 'truetype',
  fonts: [{ src: prestigeBold, fontWeight: 'bold' }]
})
Font.register({
  family: 'prestige',
  format: 'truetype',
  fonts: [{ src: prestige }]
})
Font.register({
  family: 'times',
  fonts: [{ src: times }]
})

export const PdfRenderer = ({ data, onRender }: { data: Menu; onRender: () => void }) => {
  if (!data) return <></>

  const COLOR = '#252525'

  const styles = StyleSheet.create({
    top: {
      width: '100%',
      textAlign: 'center',
      borderTop: `1px solid ${COLOR}`,
      position: 'absolute',
      top: 0,
      height: '1px'
    },
    page: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#FFFFFF',
      borderRadius: 5,
      width: '100%',
      color: `${COLOR}`,
      fontSize: '7.5pt',
      fontFamily: 'prestige',
      padding: '15px'
    },
    border: {
      borderRight: `1px solid ${COLOR}`,
      borderLeft: `1px solid ${COLOR}`,
      width: '100%'
    },
    row: {
      width: '98%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '0 auto'
    },
    section: {
      width: '50%'
    },
    footer: {
      width: '100%',
      textAlign: 'center',
      borderBottom: `1px solid ${COLOR}`,
      position: 'absolute',
      bottom: 0
    },
    note: {
      border: `1px solid ${COLOR}`,
      padding: '10px',
      width: '90%',
      margin: '0 auto',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: '6pt',
      fontFamily: 'saa'
    },
    philosophy: {
      width: '80%',
      margin: '20px auto 0 auto',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: '6pt',
      fontFamily: 'prestige'
    },
    philosophyTitle: {
      fontFamily: 'prestige-bold',
      marginBottom: '5px',
      textDecoration: 'underline'
    }
  })

  return (
    <Document
      title={`${data.label}-${data.season}-${data.service} `}
      onRender={() => {
        onRender()
      }}
      author="BigMamma"
      subject="Menu"
      language="fr"
    >
      <Page size="A3" style={styles.page}>
        <View style={styles.border}>
          <View fixed style={styles.top}></View>
          <Header data={data} color={COLOR} />
          <Bcorp data={data} color={COLOR} />
          <View style={styles.row}>
            <View style={[styles.section, { paddingRight: '5px' }]}>
              <Products data={data.food?.slice(0, 3)} color={COLOR} />
            </View>
            <View style={styles.section}>
              <Products data={data.food?.slice(3, 8)} color={COLOR} />
            </View>
          </View>
          <View fixed style={styles.footer}>
            <PricePhilosohpy data={data} color={COLOR} />
            <Footer data={data} color={COLOR} />
          </View>
        </View>
      </Page>
      <Page size="A3" style={styles.page}>
        <View style={styles.border}>
          <View fixed style={styles.top}></View>
          <Birra data={data} color={COLOR} />
          <View style={styles.row}>
            <View style={[styles.section, { paddingRight: '5px' }]}>
              <Products data={data.beverage?.slice(1, 20)} color={COLOR} />
            </View>
            <View style={styles.section}>
              <Products data={data.beverage?.slice(0, 1)} color={COLOR} />
              <Philososphy data={data} styles={styles} color={COLOR} />
            </View>
          </View>
          <View fixed style={styles.footer}>
            <Footer data={data} color={COLOR} />
          </View>
        </View>
      </Page>
    </Document>
  )
}

function Philososphy({ data, styles, color }) {
  return (
    <>
      <View
        style={{
          borderTop: `1px solid ${color}`,
          width: '90%',
          margin: '10px auto 2px auto'
        }}
      ></View>
      <View style={styles.note}>
        <Text>{data.note}</Text>
      </View>
      <View style={styles.philosophy}>
        <Text style={styles.philosophyTitle}>{data.philosophy.label}</Text>
        <Text>{data.philosophy.description}</Text>
      </View>
    </>
  )
}

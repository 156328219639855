import { useHiddenProductsQuery } from 'api/graphql/types.generated'
import { useGraphQLClient } from 'providers/hooks'
import { useParams } from 'react-router-dom'

export function useHiddenProducts() {
  const { graphQLClient } = useGraphQLClient()
  const { slug } = useParams<{ slug: string }>()
  const queryInfo = useHiddenProductsQuery(
    graphQLClient,
    {
      slug
    },
    {
      enabled: Boolean(slug),
      initialData: {},
      suspense: false,
      useErrorBoundary: true
    }
  )

  return {
    hidden_products: queryInfo.data?.menus?.data[0]?.attributes?.hidden_products,
    hiddenIsLoading: queryInfo.isLoading,
    layoutIsFetched: queryInfo.isFetched,
    refetch: queryInfo.refetch
  }
}

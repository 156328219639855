import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMenuLayout } from 'api/hooks/useMenuLayout'
import { useGetCategories } from 'api/hooks/useGetCategories'
import { useSearchParams } from 'react-router-dom'

import { SplashScreen } from 'components/menus/splash-screen'
import { StickyCategories } from 'components/menus/sticky-categories'
import { useHiddenProducts } from 'api/hooks/useHiddenProducts'
// import { OurSurprise } from "components/menus/our-surprise";
import { Categories } from 'components/menus/categories'
import { Header } from 'components/menus/header'
import { Footer } from 'components/menus/footer'
import { getRGBColor } from 'utils'
import Skeleton from 'react-loading-skeleton'

export const Restaurant = () => {
  const [locale, setLocale] = useState(navigator.language)
  const [showLoader, setShowLoader] = useState(true)

  const { data: categories, categoriesIsFetched } = useGetCategories(locale)
  const [searchParams] = useSearchParams()

  const { layout, layoutIsFetched } = useMenuLayout(locale)
  const { hidden_products } = useHiddenProducts()
  const { i18n } = useTranslation()

  const current = i18n.language

  const handleLocale = (lng: string) => {
    i18n.changeLanguage(lng)
    setLocale(lng)
  }

  useEffect(() => {
    if (current !== locale) i18n.changeLanguage(locale)
  }, [locale, i18n, current])

  useEffect(() => {
    // Set locale to "en" if no data !
    if (!layout && layoutIsFetched) {
      setLocale('en')
    }
  }, [layoutIsFetched, layout])

  useEffect(() => {
    if (!searchParams.get('categorie')) {
      const element = document.querySelector('#header')
      if (element) {
        element.scrollIntoView(true)
      }
    }
  }, [searchParams])

  useEffect(() => {
    if (layout) {
      // eslint-disable-next-line no-console
      async function loadTitleFonts() {
        const font = new FontFace(
          'titleFont',
          `url(${layout?.title_font?.data?.attributes?.font_file?.data?.attributes?.url})`
        )
        await font.load()
        document.fonts.add(font)
        document.body.classList.add('titleFont-loaded')
      }
      async function loadBodyFonts() {
        const font = new FontFace(
          'bodyFont',
          `url(${layout?.body_font?.data?.attributes?.font_file?.data?.attributes?.url})`
        )
        await font.load()
        document.fonts.add(font)
        document.body.classList.add('bodyFont-loaded')
      }
      async function loadDescriptionFonts() {
        const font = new FontFace(
          'descriptionFont',
          `url(${layout?.description_font?.data?.attributes?.font_file?.data?.attributes?.url})`
        )
        await font.load()
        document.fonts.add(font)
        document.body.classList.add('bodyFont-loaded')
      }
      loadTitleFonts()
      loadDescriptionFonts()
      loadBodyFonts()
    }
  }, [layout])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false)
    }, 1000)
    return () => clearTimeout(timer)
    // if (categoriesIsFetched) setShowLoader(false)
  }, [categoriesIsFetched])

  if (!layout) {
    return <></>
  }

  const background_color = layout.background_color ?? 'white'
  const font_color = layout.font_color ?? 'black'

  const primary = getRGBColor(font_color, 'primary')
  const secondary = getRGBColor(background_color, 'secondary')

  if (showLoader && layout) {
    return (
      <SplashScreen
        restaurantAdress={layout.restaurant_adress}
        restaurantName={layout.restaurant}
        color={font_color}
        imgUrl={layout?.cover?.data?.attributes?.url}
      />
    )
  }

  return (
    <>
      <style>:root {`{${primary} ${secondary}}`}</style>

      <div id="main" className="h-screen overflow-x-hidden font-gowun">
        <div className="container flex justify-center w-full max-w-[768px] mx-auto">
          <div className="w-full ">
            {/* HEADER */}
            <Header
              setLocale={(lng) => handleLocale(lng)}
              wifi={layout.wifi_code}
              showCaloriesBtn={layout.show_calories_button}
              description={layout.description}
              isLoading={!layoutIsFetched}
              sub_description={layout.sub_description}
              // body_font={layout.body_font}
            />

            {/* STICKY CATEGORIES */}
            {categoriesIsFetched ? <StickyCategories categories={categories} /> : <Skeleton height={100} count={10} />}

            {/* CATEGORIES */}
            {showLoader ? (
              <div className="h-auto">
                <SplashScreen
                  restaurantAdress={layout.restaurant_adress}
                  restaurantName={layout.restaurant}
                  color={font_color}
                  imgUrl={layout?.cover?.data?.attributes?.url}
                />
              </div>
            ) : (
              <Categories categories={categories} hidden_products={hidden_products} />
            )}

            {/* FOOTER */}
            {/* <OurSurprise
              gif={layout.our_surprise?.data?.attributes?.url}
              link={layout.our_surprise_link}
              message={layout.footer_thanks_message}
              quality={layout.quality_or_nothing?.data?.attributes?.url}
              qualityContent={layout?.quality_content}
            /> */}

            <Footer
              image={layout.our_surprise?.data?.attributes?.url}
              surpriseLink={layout.our_surprise_link}
              allergens={layout.footer_allergens}
              teams={layout.footer_team}
            />
          </div>
        </div>
      </div>
    </>
  )
}

import { GlobalContext } from 'providers/global'
import React from 'react'
import { GraphQLClientContext } from './../gqlprovider'

export function useGraphQLClient() {
  const contextValue = React.useContext(GraphQLClientContext)

  if (!contextValue) {
    throw new Error('Wrap your components tree with a GraphQLClientProvider component')
  }

  return contextValue
}

export function useGlobal() {
  return React.useContext(GlobalContext)
}

// @ts-nocheck
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { Menu } from 'type'
import { Bcorp } from './../bcorp'

interface Props {
  data: Menu
  color?: string
  fontSizePercentage?: number
}

export const Footer: React.FC<Props> = ({ data, color, fontSizePercentage }) => {
  const FONT_SIZE_RATIO = fontSizePercentage || 100
  const adjustFontSize = (size) => `${(size * FONT_SIZE_RATIO) / 100}pt`
  const styles = StyleSheet.create({
    section: {
      position: 'relative',
      top: '0px',
      display: 'flex',
      flexDirection: 'column',
      // paddingBottom: "10px",
      textAlign: 'center'
    },
    flex: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      flexDirection: 'row'
    },
    line: {
      borderTop: `1px solid ${color}`,
      width: '100%',
      marginTop: '1px'
    },
    team: {
      margin: '5px 0 3px 0',
      fontFamily: 'prestige-elite-bold',
      textTransform: 'uppercase',
      fontSize: adjustFontSize(6),
      textAlign: 'center'
    },
    service: {
      margin: '1px 0',
      fontFamily: 'prestige-elite-bold',
      fontSize: adjustFontSize(6),
      textAlign: 'center'
    },
    allergens: {
      marginTop: '10px',
      fontFamily: 'prestige-elite-bold',
      textTransform: 'uppercase',
      fontSize: adjustFontSize(6),
      position: 'relative',
      textAlign: 'center'
    },
    logo: {
      top: '-6px',
      position: 'absolute'
    }
  })
  return (
    <>
      <View style={styles.section}>
        <View style={styles.line} />
        <View style={styles.line} />
        <View style={styles.line} />

        <View style={styles.flex}>
          <View style={styles.logo}>
            <Bcorp data={data} color={color} />
          </View>
          <View
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '85%',
              margin: '0 auto'
            }}
          >
            <Text style={styles.allergens}>{data.allergens}</Text>
            <Text style={styles.team}>{data.team}</Text>
            <Text style={styles.service}>{data.service}</Text>
          </View>
        </View>

        {/* <Text style={styles.adress}>{data.specificities}</Text> */}
      </View>
    </>
  )
}

// @ts-nocheck
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { Menu } from 'type'

interface Props {
  data: Menu
  color?: string
}

export const Birra: React.FC<Props> = ({ data, color }) => {
  const styles = StyleSheet.create({
    section: {
      margin: '10px 10px 0 10px',
      display: 'flex',
      flexDirection: 'column',
      padding: '5px',
      textAlign: 'center',
      border: `1px solid ${color}`
    },
    title: {
      textTransform: 'uppercase',
      fontFamily: 'saa',
      fontSize: '12pt',
      marginBottom: '10px'
    },
    description: {
      textAlign: 'justify',
      width: '80%',
      textAlignLast: 'center'
    },
    label: {
      textTransform: 'uppercase',
      fontSize: '7.5pt',
      fontFamily: 'prestige-bold',
      fontWeight: 'bold'
    },
    flex: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      alignItems: 'center',
      flexDirection: 'row'
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      textAlign: 'center',
      width: '100%',
      justifyContent: 'center'
    }
  })
  const { beers } = data
  if (beers.products.length === 0) {
    return <></>
  }
  return (
    <>
      <View style={styles.section}>
        <View style={styles.row}>
          <Text style={styles.title}>{beers.label}</Text>
          <Text style={[styles.title, { marginLeft: '5px' }]}>{beers.price}</Text>
        </View>

        {beers.products.map(({ description, label }) => {
          return (
            <View style={styles.row} key={label}>
              <Text style={styles.label}>{label}</Text>
              <Text style={{ marginTop: '3px', marginLeft: '3px' }}>{description}</Text>
            </View>
          )
        })}
      </View>
    </>
  )
}

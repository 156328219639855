import { GraphQLClient } from 'graphql-request'
import React from 'react'

type Props = {
  children: React.ReactNode
  defaultState?: GraphQLClientState
}

export type GraphQLClientState = {
  graphQLClient: GraphQLClient
}

export type GraphQLClientProviderState = GraphQLClientState | null

const ENDPOINT =
  process.env.NODE_ENV === 'production' || process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:1337/graphql'

// const ENDPOINT = 'https://back-menu-dev-9f0547f00780.herokuapp.com/graphql'

export const GraphQLClientContext = React.createContext<GraphQLClientProviderState>(null)

const initialState: GraphQLClientState = {
  graphQLClient: new GraphQLClient(ENDPOINT!, {
    headers: {
      Authorization: ''
    }
  })
}

export function GraphQLClientProvider({ children, defaultState }: Props) {
  return <GraphQLClientContext.Provider value={defaultState || initialState}>{children}</GraphQLClientContext.Provider>
}

import { useGetLocalesFromSlugQuery } from 'api/graphql/types.generated'
import { useGraphQLClient } from 'providers/hooks'
import { useParams } from 'react-router-dom'

export function useLocales() {
  const { graphQLClient } = useGraphQLClient()
  const { slug } = useParams<{ slug: string }>()
  const queryInfo = useGetLocalesFromSlugQuery(
    graphQLClient,
    {
      slug: slug!
    },
    {
      enabled: Boolean(slug),
      initialData: {},
      suspense: false,
      useErrorBoundary: true
    }
  )

  return {
    locales: queryInfo.data?.menus?.data?.map((el) => el?.attributes?.locale),
    localesIsFetched: queryInfo.isFetched,
    refetch: queryInfo.refetch
  }
}

import { Layout } from 'components/render/layout'
import { Preview } from 'components/render/preview'

export const RenderPdf = () => {
  return (
    <Layout withOptions={false}>
      <Preview />
    </Layout>
  )
}

// @ts-nocheck
import { View, StyleSheet, Svg, G, Path } from '@react-pdf/renderer'

interface Props {
  data: Menu
  color?: string
}

// Can be useful in future
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Bcorp: React.FC<Props> = ({ data, color }) => {
  const styles = StyleSheet.create({
    logo: {
      marginTop: '15px'
    }
  })

  return (
    <>
      <View>
        <Svg width={40} height={40} viewBox="0 0 50 60" style={styles.logo}>
          <G>
            <Path
              fill={color}
              d="M7.11,4.91c0.57,0.14,1.11,0.34,1.51,0.82c0.25,0.3,0.41,0.63,0.48,1.01c0.03,0.14,0,0.22-0.17,0.21
		c-0.16-0.01-0.31-0.02-0.47,0C8.14,6.98,7.93,6.92,7.75,6.57c-0.43-0.84-1.76-0.83-2.27,0c-0.5,0.82-0.5,1.7-0.03,2.54
		c0.32,0.57,0.87,0.72,1.49,0.63c0.57-0.08,0.85-0.49,0.97-0.99C7.96,8.53,8.06,8.5,8.25,8.5c0.24,0.01,0.49,0.01,0.73,0
		c0.16-0.01,0.2,0.05,0.18,0.2c-0.1,1.07-1.04,1.99-2.12,2.08c-1.9,0.15-3.18-1.02-3.17-2.91C3.88,6.71,4.31,5.75,5.4,5.18
		c0.26-0.14,0.55-0.17,0.83-0.26H7.11z"
            />
            <Path
              fill={color}
              d="M19.61,15.14C12.02,15.1,5.87,21.2,5.75,28.59c-0.13,7.63,6.09,13.89,13.74,13.92
		c7.68,0.02,13.92-6.06,13.94-13.58C33.44,21.29,27.3,15.16,19.61,15.14 M19.58,44.59c-8.88,0-16.09-7.19-15.94-16.06
		c0.15-8.61,7.34-15.61,16.18-15.49c8.49,0.11,15.82,6.99,15.7,16C35.42,37.71,28.28,44.59,19.58,44.59"
            />
            <Path
              fill={color}
              d="M19.62,55.13c-5.18,0-10.36,0-15.54,0.01c-0.27,0-0.35-0.07-0.34-0.33c0.02-0.46,0.01-0.93-0.01-1.4
		c-0.01-0.22,0.04-0.3,0.28-0.3c2.13,0.01,4.27-0.01,6.4-0.01c7.46-0.01,14.92-0.01,22.38-0.02c0.81,0,1.62,0,2.44-0.02
		c0.25-0.01,0.31,0.08,0.3,0.31c-0.02,0.47-0.02,0.95,0,1.42c0.01,0.22-0.05,0.28-0.27,0.28c-2.52,0-5.03,0.01-7.55,0.01
		c-2.7,0-5.4,0-8.1,0V55.13z"
            />
            <Path
              fill={color}
              d="M32.37,8.51c0.03,0.28,0.05,0.51,0.13,0.73c0.17,0.43,0.53,0.68,0.94,0.65c0.44-0.03,0.72-0.27,0.85-0.75
		c0.1-0.4,0.1-0.8-0.03-1.2c-0.14-0.43-0.44-0.65-0.86-0.65c-0.44,0-0.76,0.22-0.9,0.64C32.42,8.13,32.4,8.35,32.37,8.51 M34.31,7
		c0-0.62,0.01-1.19-0.01-1.76c0-0.21,0.06-0.27,0.27-0.26c0.19,0.02,0.39,0.02,0.59,0c0.25-0.03,0.29,0.09,0.29,0.3
		c0,1.69,0,3.39,0.01,5.08c0,0.2-0.05,0.28-0.26,0.26c-0.1-0.01-0.21,0-0.32,0c-0.47,0-0.47,0-0.54-0.42
		c-0.73,0.64-1.67,0.72-2.35,0.16c-0.41-0.33-0.61-0.79-0.69-1.3c-0.1-0.58-0.04-1.14,0.21-1.67C31.98,6.4,33.4,6.04,34.31,7"
            />
            <Path
              fill={color}
              d="M11.58,8.18c0.26,0,0.53,0,0.79,0c0.11,0,0.15-0.02,0.12-0.15c-0.1-0.44-0.38-0.68-0.81-0.7
		c-0.49-0.02-0.83,0.22-0.95,0.65c-0.04,0.15-0.02,0.21,0.15,0.21C11.11,8.17,11.34,8.18,11.58,8.18 M12.18,8.91
		c-0.42,0-0.84,0.01-1.26,0c-0.22-0.01-0.24,0.08-0.2,0.26c0.07,0.35,0.24,0.62,0.6,0.71c0.39,0.1,0.81,0.11,1.08-0.23
		c0.19-0.23,0.39-0.28,0.65-0.26c0.17,0.02,0.43-0.08,0.49,0.05c0.07,0.16-0.1,0.35-0.2,0.52c-0.4,0.65-1.04,0.83-1.74,0.82
		c-1.12-0.02-1.94-0.75-2.02-1.79c-0.11-1.33,0.49-2.24,1.66-2.48c1.15-0.24,2.24,0.53,2.39,1.7c0.09,0.7,0.09,0.7-0.63,0.7
		C12.73,8.91,12.45,8.91,12.18,8.91"
            />
            <Path
              fill={color}
              d="M28.85,8.17L28.85,8.17c0.25-0.01,0.51-0.01,0.76,0c0.14,0,0.16-0.05,0.13-0.17
		c-0.09-0.42-0.36-0.67-0.78-0.69c-0.49-0.02-0.85,0.21-0.97,0.62c-0.05,0.17-0.04,0.28,0.19,0.26C28.4,8.15,28.62,8.17,28.85,8.17
		 M30.84,9.37c-0.11,0.41-0.3,0.73-0.61,0.97c-0.86,0.69-2.37,0.49-3.02-0.39c-0.54-0.72-0.54-1.97,0-2.68
		c0.54-0.72,1.53-1.02,2.39-0.73c1.02,0.35,1.35,1.36,1.33,2.17c0,0.19-0.14,0.15-0.25,0.15c-0.82,0-1.64,0.01-2.47-0.01
		c-0.25,0-0.28,0.1-0.24,0.29c0.07,0.33,0.23,0.6,0.58,0.7c0.42,0.11,0.84,0.1,1.13-0.26c0.16-0.2,0.33-0.23,0.55-0.22
		C30.43,9.37,30.62,9.37,30.84,9.37"
            />
            <Path
              fill={color}
              d="M24.66,5.88c-0.12-0.01-0.24-0.03-0.35-0.02c-0.38,0-0.55,0.23-0.47,0.6c0.02,0.09,0.07,0.1,0.14,0.1
		c0.09,0,0.18,0.01,0.26,0c0.3-0.05,0.4,0.06,0.37,0.36c-0.04,0.42,0.09,0.42-0.41,0.39c-0.3-0.01-0.38,0.07-0.37,0.37
		c0.02,0.91,0,1.82,0.02,2.73c0,0.19-0.05,0.26-0.25,0.25c-0.21-0.01-0.43-0.01-0.65,0.01c-0.23,0.02-0.28-0.07-0.28-0.28
		c0.01-0.92-0.01-1.84,0.01-2.76c0.01-0.26-0.08-0.34-0.33-0.32c-0.11,0.01-0.3,0.08-0.33-0.08C22,7.03,21.99,6.79,22.05,6.6
		c0.04-0.11,0.25-0.05,0.38-0.04c0.18,0,0.26-0.03,0.26-0.24c0.02-0.86,0.44-1.27,1.3-1.31c0.69-0.03,0.69-0.03,0.68,0.65
		C24.67,5.74,24.67,5.81,24.66,5.88"
            />
            <Path
              fill={color}
              d="M17.88,8.67c0-0.37-0.01-0.74,0-1.1c0.01-0.18-0.06-0.25-0.24-0.23c-0.09,0.01-0.18,0-0.26,0.01
		c-0.15,0.02-0.2-0.05-0.18-0.18c0-0.03,0-0.06,0-0.09c0-0.47,0-0.48,0.46-0.49c0.17,0,0.23-0.06,0.22-0.22
		c-0.01-0.25,0-0.5-0.01-0.76c-0.01-0.18,0.04-0.27,0.24-0.26c0.29,0.02,0.67-0.09,0.85,0.05c0.2,0.15,0.05,0.54,0.06,0.83
		c0.01,0.2-0.02,0.37,0.29,0.35c0.57-0.03,0.57-0.01,0.54,0.56c0,0.14-0.05,0.2-0.18,0.18c-0.01,0-0.02,0-0.03,0
		c-0.62,0.01-0.62,0.01-0.62,0.62c0,0.47,0,0.95,0,1.42c0,0.34,0.09,0.43,0.43,0.43c0.39,0.01,0.39,0.01,0.39,0.37
		c0,0.51,0,0.51-0.51,0.51c-0.29,0-0.59,0.02-0.87-0.07c-0.34-0.12-0.55-0.33-0.56-0.7c-0.01-0.42,0-0.83,0-1.25H17.88z"
            />
            <Path
              fill={color}
              d="M3.76,48.69c0-1.54,1.28-2.49,2.67-1.99c0.5,0.18,0.83,0.52,0.99,1.03c0.07,0.21,0.07,0.34-0.21,0.3
		c-0.08-0.01-0.16-0.01-0.23,0c-0.22,0.03-0.36-0.02-0.46-0.26c-0.13-0.31-0.43-0.43-0.77-0.44c-0.38-0.01-0.69,0.14-0.87,0.48
		c-0.3,0.56-0.31,1.13-0.03,1.7c0.19,0.39,0.57,0.56,1.06,0.5c0.39-0.05,0.61-0.3,0.69-0.66c0.05-0.22,0.18-0.22,0.35-0.22
		c0.68,0,0.68,0.01,0.41,0.64c-0.27,0.62-0.77,0.94-1.43,0.99c-0.8,0.06-1.46-0.21-1.88-0.92C3.84,49.48,3.75,49.1,3.76,48.69"
            />
            <Path
              fill={color}
              d="M15.81,49.21c0.01-0.15-0.01-0.29-0.06-0.43c-0.11-0.33-0.34-0.5-0.64-0.5c-0.3,0-0.54,0.19-0.62,0.52
		c-0.07,0.29-0.08,0.6,0.03,0.89c0.11,0.28,0.31,0.44,0.62,0.43c0.3,0,0.51-0.16,0.61-0.45C15.8,49.52,15.81,49.37,15.81,49.21
		 M14.46,50.36c0,0.4-0.01,0.76,0,1.12c0,0.16-0.04,0.22-0.21,0.22c-0.74-0.02-0.6,0.11-0.61-0.59c-0.01-1.02,0-2.03-0.01-3.05
		c0-0.22,0.04-0.33,0.28-0.29c0.06,0.01,0.12,0,0.18,0c0.19,0,0.41-0.05,0.3,0.34c0.47-0.47,0.95-0.53,1.46-0.31
		c0.56,0.24,0.71,0.73,0.77,1.27c0.03,0.25-0.01,0.5-0.08,0.75C16.27,50.73,15.23,51.03,14.46,50.36"
            />
            <Path
              fill={color}
              d="M15.25,7.29c0.35-0.52,0.74-0.81,1.31-0.82c0.21,0,0.28,0.07,0.26,0.27c0,0.01,0,0.02,0,0.03
		c0,0.23,0.07,0.5-0.03,0.69c-0.11,0.2-0.4,0.04-0.62,0.08c-0.44,0.08-0.78,0.43-0.83,0.92c-0.07,0.65-0.03,1.3-0.03,1.94
		c0,0.21-0.04,0.3-0.27,0.27c-0.22-0.02-0.45-0.01-0.67,0c-0.16,0.01-0.2-0.06-0.2-0.2c0-1.22,0-2.44,0-3.66
		c0-0.16,0.04-0.23,0.21-0.22c0.21,0.01,0.43,0.01,0.64,0c0.18-0.01,0.25,0.04,0.24,0.23C15.24,6.94,15.25,7.07,15.25,7.29"
            />
            <Path
              fill={color}
              d="M24.53,49.25c-0.24,0.11-0.51,0.1-0.77,0.17c-0.23,0.06-0.35,0.19-0.33,0.44c0.02,0.24,0.2,0.3,0.39,0.32
		C24.28,50.22,24.66,49.97,24.53,49.25 M25.37,49.44c0.03,0.35-0.05,0.72,0.05,1.08c0.02,0.06,0,0.14-0.08,0.11
		c-0.25-0.08-0.58,0.21-0.76-0.19c-0.04-0.09-0.12,0.02-0.16,0.05c-0.4,0.24-0.83,0.28-1.27,0.14c-0.35-0.11-0.54-0.39-0.54-0.78
		c0-0.39,0.16-0.65,0.52-0.79c0.29-0.11,0.61-0.12,0.92-0.17c0.1-0.02,0.22-0.03,0.31-0.07c0.23-0.1,0.26-0.39,0.06-0.54
		c-0.25-0.19-0.76-0.09-0.87,0.21c-0.09,0.22-0.25,0.13-0.39,0.14c-0.15,0-0.37,0.09-0.43-0.06c-0.07-0.17,0.06-0.35,0.18-0.5
		c0.09-0.11,0.19-0.19,0.32-0.25c0.56-0.23,1.12-0.23,1.68-0.02c0.32,0.12,0.45,0.39,0.45,0.71C25.38,48.82,25.37,49.12,25.37,49.44
		"
            />
            <Path
              fill={color}
              d="M10.07,49.19c-0.01-0.1,0-0.22-0.03-0.33c-0.08-0.27-0.22-0.5-0.54-0.54c-0.35-0.04-0.58,0.05-0.71,0.33
		c-0.18,0.38-0.17,0.77,0,1.15c0.11,0.23,0.31,0.34,0.57,0.34c0.25,0,0.45-0.09,0.58-0.31C10.06,49.62,10.06,49.41,10.07,49.19
		 M7.86,49.23c0-0.92,0.58-1.53,1.49-1.54c0.91-0.01,1.52,0.6,1.52,1.51c0,0.94-0.59,1.56-1.5,1.56
		C8.47,50.76,7.87,50.14,7.86,49.23"
            />
            <Path
              fill={color}
              d="M31.44,49.2c-0.01-0.12-0.01-0.23-0.03-0.34c-0.09-0.4-0.32-0.59-0.67-0.6c-0.36,0-0.56,0.18-0.67,0.59
		c-0.05,0.16-0.05,0.33-0.03,0.49c0.03,0.4,0.22,0.65,0.51,0.73C31.06,50.21,31.43,49.85,31.44,49.2 M30.72,47.66
		c0.92-0.01,1.53,0.58,1.53,1.5c0.01,0.91-0.59,1.54-1.49,1.55c-0.93,0.01-1.54-0.59-1.55-1.52C29.2,48.28,29.8,47.67,30.72,47.66"
            />
            <Path
              fill={color}
              d="M19.22,49.2c0-0.5-0.17-0.79-0.5-0.88c-0.27-0.07-0.52-0.04-0.69,0.18c-0.28,0.37-0.28,0.79-0.12,1.21
		c0.1,0.26,0.29,0.4,0.58,0.41C18.95,50.13,19.22,49.79,19.22,49.2 M18.52,50.72c-0.92,0-1.5-0.58-1.51-1.51
		c0-0.93,0.58-1.52,1.49-1.53c0.93,0,1.53,0.59,1.53,1.53C20.03,50.13,19.44,50.72,18.52,50.72"
            />
            <Path
              fill={color}
              d="M33.52,48.12c0.33-0.38,0.65-0.49,1.04-0.46c0.59,0.04,0.9,0.33,0.92,0.91c0.02,0.62,0.01,1.24,0.01,1.86
		c0,0.14-0.05,0.18-0.19,0.18c-0.6,0.02-0.6,0.03-0.61-0.56c0-0.43-0.01-0.85-0.03-1.28c-0.02-0.34-0.19-0.5-0.48-0.51
		c-0.3-0.01-0.53,0.16-0.58,0.46c-0.1,0.53-0.04,1.06-0.04,1.6c0,0.22-0.05,0.31-0.29,0.3c-0.52-0.01-0.52,0-0.52-0.52
		c0-0.72,0-1.44-0.01-2.15c0-0.17,0.04-0.26,0.23-0.23c0.08,0.01,0.16,0.01,0.23,0C33.43,47.71,33.61,47.73,33.52,48.12"
            />
            <Path
              fill={color}
              d="M21.57,8.61c0,0.6-0.01,1.2,0,1.8c0,0.21-0.06,0.27-0.27,0.26c-0.21-0.02-0.43-0.01-0.65,0
		c-0.15,0.01-0.23-0.02-0.23-0.2c0.01-1.23,0.01-2.46,0-3.69c0-0.17,0.05-0.22,0.22-0.21c0.29,0.01,0.66-0.09,0.85,0.05
		c0.19,0.14,0.05,0.52,0.06,0.8C21.57,7.81,21.56,8.21,21.57,8.61L21.57,8.61z"
            />
            <Path
              fill={color}
              d="M26.44,8.59c0,0.61,0,1.22,0,1.83c0,0.16-0.04,0.23-0.21,0.22c-0.25-0.01-0.51-0.01-0.76,0
		c-0.13,0-0.17-0.05-0.17-0.17c0-1.25,0-2.49,0-3.74c0-0.15,0.08-0.17,0.2-0.17c0.24,0,0.49,0.01,0.73-0.01
		c0.18-0.01,0.21,0.07,0.21,0.22C26.43,7.37,26.44,7.98,26.44,8.59L26.44,8.59z"
            />
            <Path
              fill={color}
              d="M26.2,49.25c0-0.25-0.01-0.51,0-0.76c0.01-0.18-0.05-0.26-0.23-0.23c-0.23,0.04-0.27-0.07-0.27-0.27
		c0-0.19,0.04-0.3,0.26-0.26c0.2,0.03,0.26-0.06,0.24-0.24c-0.01-0.05,0-0.1,0-0.15c0-0.48,0-0.47,0.5-0.49
		c0.22-0.01,0.33,0.05,0.3,0.28c-0.01,0.05,0.01,0.1,0.01,0.14c0.02,0.41,0.01,0.42,0.42,0.44c0.24,0.01,0.16,0.17,0.17,0.29
		c0.01,0.13,0.02,0.24-0.18,0.25C27,48.28,27,48.3,27,48.7c0,0.32,0.02,0.64,0.01,0.96c-0.01,0.28,0.1,0.41,0.37,0.37
		c0.18-0.03,0.24,0.06,0.2,0.21c-0.04,0.15,0.15,0.4-0.18,0.42c-0.07,0.01-0.14,0.01-0.21,0.01c-0.83,0.01-1-0.16-1-0.97
		C26.2,49.54,26.2,49.4,26.2,49.25"
            />
            <Path
              fill={color}
              d="M12.13,48.23c0.2-0.29,0.45-0.48,0.78-0.54c0.21-0.04,0.35,0,0.31,0.26c-0.01,0.07-0.01,0.14,0,0.2
		c0.03,0.22-0.05,0.3-0.28,0.29c-0.47-0.01-0.76,0.27-0.78,0.75c-0.01,0.42-0.01,0.83,0,1.25c0,0.16-0.04,0.22-0.21,0.22
		c-0.61,0.01-0.61,0.02-0.61-0.57c0-0.68,0.01-1.36-0.01-2.03c-0.01-0.26,0.08-0.3,0.31-0.29C12.08,47.78,12.08,47.77,12.13,48.23"
            />
            <Path
              fill={color}
              d="M21.35,48.19c0.2-0.28,0.45-0.46,0.79-0.51c0.2-0.03,0.33,0.02,0.3,0.25c0,0.04,0,0.08,0,0.12
		c0.04,0.27,0,0.42-0.35,0.39c-0.39-0.03-0.63,0.22-0.7,0.6c-0.07,0.44-0.04,0.89-0.03,1.33c0,0.21-0.04,0.28-0.27,0.28
		c-0.52-0.01-0.52,0-0.52-0.52c0-0.71,0-1.42-0.01-2.12c-0.01-0.23,0.07-0.28,0.28-0.27C21.28,47.74,21.28,47.73,21.35,48.19"
            />
            <Path
              fill={color}
              d="M28.79,49.22c0,0.41-0.01,0.81,0,1.22c0.01,0.17-0.06,0.21-0.22,0.21c-0.6,0.01-0.6,0.01-0.6-0.56
		c0-0.7,0.01-1.39,0-2.09c0-0.19,0.04-0.27,0.25-0.27c0.56,0.01,0.56,0,0.56,0.56C28.79,48.6,28.79,48.91,28.79,49.22"
            />
            <Path
              fill={color}
              d="M25.87,5.94c-0.58,0-0.56,0-0.58-0.57c-0.01-0.31,0.08-0.4,0.37-0.35c0.03,0.01,0.06,0,0.09,0
		C26.44,5,26.43,5,26.44,5.67c0,0.22-0.07,0.3-0.28,0.28C26.07,5.93,25.97,5.94,25.87,5.94"
            />
            <Path
              fill={color}
              d="M20.99,5.95c-0.57,0-0.55,0-0.57-0.57c-0.01-0.29,0.06-0.4,0.37-0.36c0.17,0.03,0.35,0.02,0.53,0
		c0.22-0.02,0.28,0.07,0.25,0.26c0,0.03,0,0.06,0,0.09C21.56,5.95,21.56,5.95,20.99,5.95"
            />
            <Path
              fill={color}
              d="M28.41,46.63c0.36,0,0.49,0.21,0.38,0.57c-0.03,0.09-0.09,0.09-0.16,0.09c-0.15,0-0.29-0.01-0.44,0
		c-0.17,0.01-0.22-0.07-0.21-0.22C27.99,46.54,27.94,46.64,28.41,46.63"
            />
            <Path
              fill={color}
              d="M35.63,47.04c0.06,0.01,0.11,0.02,0.17,0.03c0.19,0.06,0.33-0.02,0.42-0.17c0.08-0.14,0.07-0.28-0.05-0.4
		c-0.13-0.13-0.28-0.15-0.42-0.06c-0.15,0.09-0.24,0.23-0.16,0.42c0.02,0.05,0.05,0.1,0.03,0.16c-0.17-0.17-0.16-0.36-0.02-0.55
		c0.12-0.16,0.3-0.18,0.47-0.12c0.18,0.06,0.27,0.2,0.26,0.4c-0.01,0.2-0.11,0.32-0.29,0.39C35.88,47.19,35.74,47.15,35.63,47.04"
            />
            <Path
              fill={color}
              d="M35.76,47.04c-0.04-0.24-0.08-0.38,0-0.5c0.05-0.07,0.18-0.05,0.25-0.01c0.16,0.1,0,0.27,0.06,0.4
		C35.95,46.79,35.84,46.68,35.76,47.04"
            />
            <Path
              fill={color}
              d="M18.3,21.85L18.3,21.85c-0.89-0.01-1.78,0-2.67-0.01c-0.25-0.01-0.3,0.08-0.3,0.31c0.01,1.71,0.01,3.41,0,5.12
		c0,0.22,0.05,0.3,0.29,0.29c1.48-0.01,2.96-0.01,4.44-0.01c0.63,0,1.25-0.03,1.86-0.18c0.99-0.24,1.75-0.75,2.02-1.8
		c0.12-0.45,0.13-0.9,0.08-1.36c-0.07-0.68-0.3-1.3-0.88-1.73c-0.65-0.48-1.41-0.6-2.19-0.63C20.06,21.83,19.18,21.85,18.3,21.85
		 M18.58,35.81L18.58,35.81c0.94,0,1.88,0,2.82,0c0.03,0,0.06,0,0.09,0c0.75-0.04,1.47-0.18,2.1-0.63c0.83-0.59,1.19-1.43,1.23-2.42
		c0.06-1.27-0.41-2.29-1.71-2.82c-0.67-0.27-1.38-0.36-2.09-0.36c-1.8,0-3.6,0.01-5.4,0c-0.21,0-0.28,0.04-0.28,0.27
		c0.01,1.9,0.01,3.8,0,5.7c0,0.23,0.08,0.27,0.28,0.27C16.6,35.8,17.59,35.81,18.58,35.81 M23.76,28.27c0.32,0.1,0.6,0.17,0.86,0.27
		c1.49,0.59,2.34,1.71,2.57,3.26c0.24,1.68-0.17,3.19-1.37,4.44c-0.93,0.96-2.1,1.47-3.42,1.53c-3.08,0.12-6.16,0.04-9.25,0.07
		c-0.29,0-0.22-0.18-0.22-0.32c-0.01-3.97-0.01-7.93-0.01-11.9c0-1.83-0.01-3.66-0.03-5.5c0-0.25,0.06-0.32,0.32-0.32
		c2.56,0.01,5.11,0,7.66,0.01c0.91,0.01,1.82,0.07,2.7,0.33c1.53,0.45,2.76,2,2.87,3.58c0.08,1.19-0.12,2.29-0.9,3.25
		C25.08,27.55,24.49,27.95,23.76,28.27"
            />
          </G>
        </Svg>
      </View>
    </>
  )
}

import { Accordion } from '../accordion'
import { VideoModal } from '../video'
import { useEffect, useState } from 'react'
import { Product } from '../product'
import { ProductType, TagType } from '../../../type/menu'
import { FilterModal } from '../filterModal'
import { FilterButton } from '../filterButton'
import { filterProductByTags } from '../../../utils/filters'

type SubCategoryProps = {
  title: string
  description: string
  products: ProductType[]
  hiddenProductIds: number[]
  backgroundColor?: string | null
  isOpen: boolean
  onClick: (subCategoryTitle: string | null) => void
  noMatchMessage?: string
}

export default function SubCategory({
  title,
  description,
  products,
  hiddenProductIds,
  backgroundColor,
  isOpen = false,
  onClick,
  noMatchMessage
}: SubCategoryProps) {
  const [isVideoModalOpened, setIsVideoModalOpened] = useState(false)
  const [filteredTags, setFilteredTags] = useState<{ marketingTitles: string[]; titles: string[] }>({
    marketingTitles: [],
    titles: []
  })
  const allFilteredTags = [...filteredTags.marketingTitles, ...filteredTags.titles]

  const [marketingTitles, setMarketingTitles] = useState<string[]>([])
  const [titles, setTitles] = useState<string[]>([])
  const [isFilterModalOpened, setIsFilterModalOpened] = useState(false)

  const showFilterButton = products[0].sub_category.attributes.show_filters
  const thereAreTagsToFilter = marketingTitles.length > 0 || titles.length > 0
  const numberOfActivatedTags = filteredTags.marketingTitles.length + filteredTags.titles.length
  const { products: filteredProducts, isPerfectMatch } = filterProductByTags(products, filteredTags)

  useEffect(() => {
    const filteredCategories = products.filter((category) => category.sub_category?.attributes?.title === title)
    const tags = filteredCategories.map((category) => category.product.attributes.tags).flat()
    const marketingTitles = tags.reduce((acc: string[], tag: TagType) => {
      if (acc.indexOf(tag.marketing_title) < 0) {
        acc.push(tag.marketing_title)
      }
      return acc
    }, [])
    const titles = tags.reduce((acc: string[], tag: TagType) => {
      if (acc.indexOf(tag.title) < 0) {
        acc.push(tag.title)
      }
      return acc
    }, [])

    setMarketingTitles(marketingTitles)
    setTitles(titles)
  }, [products, title])

  const showFilterModal = () => {
    setIsFilterModalOpened(true)
  }

  const handleOnFilter = ({ marketingTitles, titles }: { marketingTitles: string[]; titles: string[] }) => {
    setFilteredTags({
      marketingTitles,
      titles
    })

    const element = document.getElementById(`sub_category_${title}`)
    if (element) {
      const scroll = setTimeout(() => {
        element.scrollIntoView(true)
        clearTimeout(scroll)
      }, 10)
    }
  }

  return (
    <Accordion
      isOpen={isOpen}
      handleToggle={(isOpen) => onClick(isOpen ? title : null)}
      title={title}
      description={description}
      content={
        <div>
          {allFilteredTags.length > 0 && !isPerfectMatch && noMatchMessage && (
            <p className="text-primary text-[13px] py-10 font-prestige px-5">{noMatchMessage}</p>
          )}
          {thereAreTagsToFilter && showFilterButton && (
            <FilterButton
              onClick={showFilterModal}
              backgroundColor={backgroundColor}
              numberOfActivatedTags={numberOfActivatedTags}
              parentId={`sub_category_${title}`}
            />
          )}
          {isFilterModalOpened && (
            <FilterModal
              onClose={() => setIsFilterModalOpened(false)}
              backgroundColor={backgroundColor}
              titles={titles}
              marketingTitles={marketingTitles}
              activatedTitles={filteredTags.titles}
              activatedMarketingTitles={filteredTags.marketingTitles}
              onFilter={handleOnFilter}
              section1Title={products[0]?.sub_category?.attributes?.filter_title_section1 ?? ''}
              section2Title={products[0]?.sub_category?.attributes?.filter_title_section2 ?? ''}
              parentId={`sub_category_${title}`}
              subCategoryTitle={title}
            />
          )}
          {filteredProducts.map((p) => {
            const isVideo = p?.product?.attributes.is_video_product

            if (isVideo) {
              return (
                <div key={p.product.attributes.product_id}>
                  <VideoModal
                    isOpen={isVideoModalOpened}
                    closeModal={() => setIsVideoModalOpened(false)}
                    content={p?.product?.attributes.video}
                  />
                  <div className="w-full border-t border-primary">
                    <img
                      src={p?.product?.attributes?.gif}
                      alt="gif"
                      className="mx-auto my-4 cursor-pointer w-80"
                      onClick={() => setIsVideoModalOpened(true)}
                    />
                  </div>
                </div>
              )
            }

            if (hiddenProductIds?.includes(+p.product.attributes.product_id)) {
              return <></>
            }

            return (
              <div className="px-[23px]" key={p.product.id}>
                <Product
                  hidden_products={hiddenProductIds}
                  product={p.product}
                  filteredTags={allFilteredTags}
                  isPerfectMatchFilter={isPerfectMatch}
                />
              </div>
            )
          })}
        </div>
      }
    />
  )
}

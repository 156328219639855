// @ts-nocheck
import { useHideProductMutation } from 'api/graphql/types.generated'
import { useGraphQLClient } from 'providers/hooks'

export function useHideProduct({
  product_id,
  slug_menu
}: {
  product_id: string | null | undefined
  slug_menu: string | undefined
}) {
  const { graphQLClient } = useGraphQLClient()

  const queryInfo = useHideProductMutation(
    graphQLClient,
    {
      product_id,
      slug_menu
    },
    {
      'Content-Type': 'application/json'
    },
    {
      enabled: Boolean(product_id) && Boolean(slug_menu),
      initialData: {},
      suspense: false,
      useErrorBoundary: true
    }
  )
  return {
    data: queryInfo.data?.hideProduct,
    isLoading: queryInfo.isLoading,
    mutate: queryInfo.mutateAsync
  }
}

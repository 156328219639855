// @ts-nocheck
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { Menu } from 'type'

interface Props {
  data: Menu
  color?: string
  fontSizePercentage?: number
}

export const Birra: React.FC<Props> = ({ data, color, fontSizePercentage }) => {
  const FONT_SIZE_RATIO = fontSizePercentage || 100
  const adjustFontSize = (size) => `${(size * FONT_SIZE_RATIO) / 100}pt`
  const styles = StyleSheet.create({
    section: {
      marginBottom: '20px',
      display: 'flex',
      flexDirection: 'column',
      padding: '10px',
      textAlign: 'center',
      border: `1px solid ${color}`
    },
    title: {
      fontFamily: 'pinyon',
      fontSize: adjustFontSize(17),
      marginBottom: '10px'
    },
    description: {
      textAlign: 'justify',
      width: '80%',
      textAlignLast: 'center'
    },
    label: {
      textTransform: 'uppercase',
      fontSize: adjustFontSize(7),
      fontFamily: 'saa-e-d',
      letterSpacing: '0.5px'
    },
    flex: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      alignItems: 'center',
      flexDirection: 'row'
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      textAlign: 'center',
      width: '100%',
      justifyContent: 'center'
    }
  })
  const { beers } = data
  if (beers.products.length === 0) {
    return <></>
  }
  return (
    <>
      <View style={styles.section}>
        <View style={styles.row}>
          <Text style={styles.title}>{beers.label}</Text>
          <Text style={{ ...styles.label, marginTop: '-6px' }}>{beers.price}</Text>
        </View>

        {beers.products.map(({ description, label }) => {
          return (
            <View style={{ ...styles.row, marginBottom: '4px' }} key={label}>
              <Text style={styles.label}>{label}</Text>
              <Text style={{ marginLeft: '3px' }}>{description}</Text>
            </View>
          )
        })}
      </View>
    </>
  )
}

import { useGetAvailableCategories } from 'api/graphql/types.generated'
import { useGraphQLClient } from 'providers/hooks'
import { useParams, useSearchParams } from 'react-router-dom'
// import { useParams } from "react-router-dom";

export function useGetCategories(locale: string) {
  const { graphQLClient } = useGraphQLClient()
  const { slug } = useParams<{ slug: string }>()
  const [searchParams] = useSearchParams()
  const sanitizeLocale = locale.split('-')[0]
  const queryInfo = useGetAvailableCategories(
    graphQLClient,
    {
      // TODO: add locale
      slug: slug!,
      queryURL: searchParams.get('service'),
      queryDay: searchParams.get('day'),
      locale: sanitizeLocale
    },
    {
      enabled: Boolean(slug),
      initialData: {},
      suspense: false,
      useErrorBoundary: true
    }
  )

  return {
    data: queryInfo.data?.getAvailableCategories ? JSON.parse(queryInfo.data?.getAvailableCategories) : [],
    categoriesIsFetched: queryInfo.isFetched,
    refetch: queryInfo.refetch
  }
}

interface Props {
  setQuery: (query: string) => void
}

export const Search: React.FC<Props> = ({ setQuery }) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">Search product by title</label>
      <div className="relative mt-1">
        <input
          placeholder="antipasto misto, ..."
          className="w-full rounded-md border border-gray-300 bg-white py-5 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
        />
      </div>
    </div>
  )
}

import { useState } from 'react'
import { useGlobal } from 'providers/hooks'
import { LanguageSwitcher } from '../language-switcher'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

interface Props {
  setLocale: (lng: string) => void
  wifi: string | undefined | null
  description: string | undefined | null
  showCaloriesBtn: boolean | undefined | null
  isLoading: boolean
  sub_description: string | undefined | null
}

export const Header: React.FC<Props> = ({
  setLocale,
  wifi,
  showCaloriesBtn,
  description,
  isLoading,
  sub_description
  // body_font
}) => {
  const [state, setState] = useState({
    allergens: false,
    calories: false
  })

  const [searchParams] = useSearchParams()

  const { t } = useTranslation()
  const global = useGlobal()

  const handleAllergens = () => {
    setState({ allergens: !state.allergens, calories: state.calories })
    global.handleAllergens()
  }

  const handleCalories = () => {
    setState({ allergens: state.allergens, calories: !state.calories })
    global.handleCalories()
  }

  if (isLoading) {
    return <Skeleton height={30} count={3} />
  }

  return (
    <>
      <div id="header" className="pt-3 text-center text-primary">
        <LanguageSwitcher setLocale={(lng) => setLocale(lng)} />
        {wifi && <p className="uppercase font-saaFD">OPEN WIFI: {wifi}</p>}
        <div className="px-[23px] py-[15px] md:mx-0 flex flex-col gap-[23px]">
          <p className="uppercase text-[28px] -tracking-[0.35px] leading-[28px] whitespace-pre-wrap font-mammaDescription">
            {description}
          </p>
          {sub_description ? (
            <p className="uppercase text-[13px] -tracking-[0.35px] leading-[18px] whitespace-pre-wrap font-mammaBody">
              {sub_description}
            </p>
          ) : null}
        </div>
      </div>

      {searchParams.get('categorie') && (
        <div
          id="sticky-info"
          className="sticky top-0 z-50 flex items-center justify-center w-full h-10 px-3 bg-white gap-x-1 text-primary"
        >
          <button className="text-[11px] -tracking-[0.35px] font-prestige underline" onClick={handleAllergens}>
            {state.allergens ? t('hideAllergens') : t('showAllergens')}
          </button>

          {showCaloriesBtn && (
            <>
              <span>-</span>
              <button
                className="text-[11px] -tracking-[0.35px] font-prestige underline underline-offset-[1.5px]"
                onClick={handleCalories}
              >
                {state.calories ? t('hideCalories') : t('showCalories')}
              </button>
            </>
          )}
        </div>
      )}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type FilterButtonProps = {
  onClick: () => void
  backgroundColor?: string | null
  numberOfActivatedTags: number
  parentId?: string
}

export const FilterButton = ({ onClick, backgroundColor, numberOfActivatedTags, parentId }: FilterButtonProps) => {
  const { t } = useTranslation()
  const [isParentHeaderSticky, setIsParentHeaderSticky] = useState(false)

  useEffect(() => {
    const container = document.querySelector('#main')

    if (!container || !parentId) {
      return
    }

    const handleScroll = () => {
      const element = document.getElementById(parentId)

      if (element) {
        const rect = element.getBoundingClientRect()

        if (rect.top < 10) {
          setIsParentHeaderSticky(true)
        } else {
          setIsParentHeaderSticky(false)
        }
      }
    }

    container.addEventListener('scroll', handleScroll)
    return () => container.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className="fixed left-2 bottom-5 ml-5 z-40">
      <button
        onClick={onClick}
        style={{ backgroundColor: backgroundColor ?? '' }}
        className="gap-2 text-white bg-primary flex justify-center items-center px-2 py-2 font-saaFD min-w-[40px] tracking-widest h-[40px] text-[13px]"
      >
        <img src="/assets/icons/filters.svg" alt="filter" className="w-5 h-5" />
        {isParentHeaderSticky ? null : numberOfActivatedTags ? <p>{t('showMyFilters')}</p> : <p>{t('filters')}</p>}
      </button>
      {!!numberOfActivatedTags && (
        <p
          style={{ backgroundColor: backgroundColor ?? '' }}
          className="absolute -top-3 -right-3 text-[13px] rounded-full bg-primary text-white w-[25px] h-[25px] flex justify-center items-center font-saaFD"
        >
          {numberOfActivatedTags}
        </p>
      )}
    </div>
  )
}

import { useAllMenusQuery } from 'api/graphql/types.generated'
import { useGraphQLClient } from 'providers/hooks'

export function useMenuList(locale: string) {
  const { graphQLClient } = useGraphQLClient()
  const sanitizeLocale = locale.split('-')[0]

  const queryInfo = useAllMenusQuery(
    graphQLClient,
    { locale: sanitizeLocale },
    {
      initialData: {},
      suspense: false,
      useErrorBoundary: true,
      onSuccess: () => {
        console.log(Date.now(), `Fetching list succeed`)
      }
    }
  )

  // eslint-disable-next-line no-console
  console.log('queryInfo', queryInfo)

  return {
    data: queryInfo.data?.menus?.data.map((el) => el.attributes),
    isFetched: queryInfo.isFetched,
    refetch: queryInfo.refetch
  }
}

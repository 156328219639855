import React from 'react'

interface GlobalContextType {
  handleAllergens: () => void
  handleCalories: () => void
  handleSlug: (slug: string) => void
  showAllergens: boolean
  showCalories: boolean
  currentSlug: string
}

export const GlobalContext = React.createContext<GlobalContextType>(null!)

export function GlobalProvider({ children }: { children: React.ReactNode }) {
  const [showAllergens, setShowAllergens] = React.useState(false)
  const [showCalories, setShowCalories] = React.useState(false)
  const [currentSlug, setSlug] = React.useState('')

  const handleSlug = (slug: string) => {
    setSlug(slug)
  }

  const handleAllergens = () => {
    setShowAllergens(!showAllergens)
  }
  const handleCalories = () => {
    setShowCalories(!showCalories)
  }

  const value = {
    handleAllergens,
    showAllergens,
    handleCalories,
    showCalories,
    handleSlug,
    currentSlug
  }

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}

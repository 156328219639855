export const NoResult = () => {
  return (
    <div className="bg-white shadow rounded-lg mb-4">
      <div className="px-4 py-5 sm:p-6">
        <div className="text-center">
          <h1 className="text-[100px]">🍽</h1>
          <p>No product found ...</p>
        </div>
      </div>
    </div>
  )
}

import { useLocales } from 'api/hooks/useGetLocalesFormSlug'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

interface Props {
  setLocale: (lng: string) => void
}

export const LanguageSwitcher: React.FC<Props> = ({ setLocale }) => {
  const [, setSearchParams] = useSearchParams()
  const { locales } = useLocales()

  const { i18n } = useTranslation()
  const current = i18n.language

  const handleLocale = (l: string) => {
    setSearchParams({})
    setLocale(l)
  }

  if (!locales) {
    return <></>
  }

  return (
    <div className="text-primary flex justify-center text-[17px] font-saaCD">
      {locales.map((l, idx) => {
        if (!l) return <></>
        return (
          <React.Fragment key={l}>
            <button onClick={() => handleLocale(l)} className={`uppercase ${current === l && 'underline'}`}>
              {l}
            </button>
            {locales?.length - 1 !== idx && <p className="mx-2 font-bold">/</p>}
          </React.Fragment>
        )
      })}
    </div>
  )
}

import { ProductType, TagType } from '../type/menu'

type UnsortedFilteredProduct = {
  matchingTags: number
  product: ProductType
}

export const filterProductByTags = (products: ProductType[], tags: { titles: string[]; marketingTitles: string[] }) => {
  if (tags.titles.length === 0 && tags.marketingTitles.length === 0) {
    return {
      products,
      isPerfectMatch: true
    }
  }

  const allTags = [...tags.titles, ...tags.marketingTitles]
  const unsortedFilteredProducts: UnsortedFilteredProduct[] = []

  for (let i = 0; i < products.length; i++) {
    const allTagsToFilter = [...tags.titles, ...tags.marketingTitles]
    const productTitleTags = products[i].product.attributes.tags.map((tag: TagType) => tag.title)
    const productMarketingTitleTags = products[i].product.attributes.tags.map((tag: TagType) => tag.marketing_title)
    const allProductTags = [...productTitleTags, ...productMarketingTitleTags]

    const matchingTags = allProductTags.filter((tag) => allTagsToFilter.includes(tag))

    if (matchingTags.length > 0) {
      unsortedFilteredProducts.push({ product: products[i], matchingTags: matchingTags.length })
    }
  }

  const sortedProductsByMatchingTags = unsortedFilteredProducts.sort((a, b) =>
    a.matchingTags > b.matchingTags ? -1 : 1
  )
  const thereIsProductFullMatching = sortedProductsByMatchingTags[0].matchingTags === allTags.length

  if (thereIsProductFullMatching) {
    return {
      products: sortedProductsByMatchingTags
        .filter((product) => product.matchingTags === allTags.length)
        .map((sorting) => sorting.product),
      isPerfectMatch: true
    }
  }

  return {
    products: sortedProductsByMatchingTags.map((sorting) => sorting.product),
    isPerfectMatch: false
  }
}

import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type ScrollToTopButtonProps = {
  backgroundColor?: string | null
}

export const ScrollToTopButton = ({ backgroundColor }: ScrollToTopButtonProps) => {
  const [scrollY, setScrollY] = useState(0)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const container = document.querySelector('#main')

    if (!container) {
      return
    }

    const handleScroll = ({ target }: any) => {
      setScrollY(target.scrollTop)
    }

    container.addEventListener('scroll', handleScroll)
    return () => container.removeEventListener('scroll', handleScroll)
  }, [])

  const handleOnScrollToTop = () => {
    const query = searchParams.get('categorie')
    const category = document.querySelector(`#${query}`)

    if (category) {
      category?.scrollIntoView(true)
    } else {
      document.getElementById('categories')?.scrollIntoView(true)
    }
  }

  if (scrollY < 200) {
    return <></>
  }

  return (
    <div className="fixed right-7 bottom-5 z-40">
      <button
        onClick={handleOnScrollToTop}
        style={{ backgroundColor: backgroundColor ?? '' }}
        className="gap-2 text-white bg-primary flex justify-center items-center px-2 py-2 font-saaFD min-w-[40px] h-[40px] text-[13px]"
      >
        <img src="/assets/icons/top-arrow.svg" alt="scroll to top" className="w-5 h-5" />
      </button>
    </div>
  )
}

import { Category } from '../category'
import { ScrollToTopButton } from '../../scrollToTop'

export const Categories = ({ categories, hidden_products }: { categories: any; hidden_products: any }) => {
  return (
    <div id="categories" style={{ scrollMarginBlockStart: 100 }}>
      <ScrollToTopButton />
      {categories?.map((cat: any) => {
        return (
          <Category
            hidden_products={hidden_products}
            key={cat.category?.id}
            title={cat.category?.attributes?.title}
            subtitle={cat.category?.attributes?.subtitle}
            description={cat.category?.attributes?.description}
            image={cat.category?.attributes?.image}
            imagePlacement={cat.category?.attributes?.gif_placement}
            footerDescription={cat.category?.attributes?.footer_description}
            products={cat.products}
            backgroundColor={cat.category?.attributes?.background_color}
            hideProducts={cat.category?.attributes?.hide_products}
            filterTitleSection1={cat.category?.attributes?.filter_title_section1}
            filterTitleSection2={cat.category?.attributes?.filter_title_section2}
            showFilters={cat.category?.attributes?.show_filters}
            noMatchMessage={cat.category?.attributes?.no_match_message}
          />
        )
      })}
    </div>
  )
}

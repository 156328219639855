import { useMenuLayoutQuery } from 'api/graphql/types.generated'
import { useGraphQLClient } from 'providers/hooks'
import { useParams } from 'react-router-dom'

export function useMenuLayout(locale: string) {
  const { graphQLClient } = useGraphQLClient()
  const { slug } = useParams<{ slug: string }>()
  const sanitizeLocale = locale.split('-')[0]
  const queryInfo = useMenuLayoutQuery(
    graphQLClient,
    {
      slug,
      locale: sanitizeLocale
    },
    {
      enabled: Boolean(slug),
      initialData: {},
      suspense: false,
      useErrorBoundary: true
    }
  )

  return {
    layout: queryInfo.data?.menus?.data[0]?.attributes,
    layoutIsLoading: queryInfo.isLoading,
    layoutIsFetched: queryInfo.isFetched,
    refetch: queryInfo.refetch
  }
}

// @ts-nocheck
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { Menu } from 'type'
import { Product } from '../product'

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '3px',
    textAlign: 'left',
    margin: '0px 2px'
  },
  flex: {
    display: 'flex',
    width: '98%',
    flexDirection: 'column',
    paddingLeft: '5px'
  },
  wrap: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  }
})

interface Props {
  data: Menu['food']
  color?: string
}

export const Products: React.FC<Props> = ({ data, color }) => {
  return (
    <View style={styles.section}>
      {data.map((item) => {
        return (
          <View key={item.order} wrap={false}>
            <View style={styles.flex}>
              <Text
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  fontFamily: 'saa',
                  fontSize: '12pt',
                  paddingTop: '20px'
                }}
              >
                {item.label}
              </Text>
              <Text
                style={{
                  textTransform: 'uppercase',
                  fontSize: '6pt',
                  fontFamily: 'saa',
                  textDecoration: 'underline',
                  marginBottom: '2px'
                }}
              >
                {item.subtitle}
              </Text>
            </View>
            <View style={styles.wrap}>
              {item.products?.map((product, idx) => (
                <Product product={product} idx={idx} key={product.label} color={color} />
              ))}
            </View>
          </View>
        )
      })}
    </View>
  )
}

import 'react-loading-skeleton/dist/skeleton.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { RenderPdf } from 'pages/renderPdf'
import { RenderPdfV2 } from 'pages/renderPdfV2'

import { Restaurant } from 'pages/restaurant'
import { RestaurantAdmin } from 'pages/restaurant-admin'

import { RestaurantList } from 'pages/restaurant-list'

import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { GraphQLClientProvider } from 'providers/gqlprovider'
import { GlobalProvider } from 'providers/global'

const queryClient = new QueryClient()

function App() {
  return (
    <GraphQLClientProvider>
      <QueryClientProvider client={queryClient}>
        <GlobalProvider>
          <BrowserRouter>
            <Routes>
              <Route path="renderpdf" element={<RenderPdf />}></Route>
              <Route path="renderpdf-v2" element={<RenderPdfV2 />}></Route>
              <Route path="/" element={<RestaurantList />}></Route>
              <Route path="/:slug" element={<Restaurant />}></Route>
              <Route path="/:slug/admin" element={<RestaurantAdmin />}></Route>
            </Routes>
          </BrowserRouter>
        </GlobalProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </GraphQLClientProvider>
  )
}

export default App

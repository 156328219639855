import { useEffect, useRef, useState } from 'react'
import { load, reset } from 'redux/slices/menu'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { Menu } from 'type'

interface Props {
  withOptions?: boolean
}

export const Upload = ({ withOptions }: Props) => {
  const dispatch = useAppDispatch()
  const menu = useAppSelector((state) => state.menu)
  const hiddenFileInput = useRef<HTMLInputElement | null>(null)
  const [color, setColor] = useState('#000000')
  const [fontSizePercentage, setFontSizePercentage] = useState(100)
  const [categoriesCount, setCategoriesCount] = useState(3)
  const [wineCategoriesCount, setWineCategoriesCount] = useState(1)
  const [philosophyPositionOnLeft, setPhilosophyPositionOnLeft] = useState(false)
  const [hideFooterOnVerso, setHideFooterOnVerso] = useState(false)
  const [warningColor, setWarningColor] = useState('')

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target.files) return
    const fileReader = new FileReader()
    fileReader.readAsText(e.target.files[0], 'UTF-8')
    const fileName = e.target.files[0].name
    fileReader.onload = (e) => {
      if (!e?.target?.result) return
      const data = e.target.result as string

      const sanitize = (data: string): Menu => {
        const lines = data.split('\n')
        const sanitizedLines = lines.map((line) => {
          return line.trim()
        })
        const sanitizedData = sanitizedLines.join('\n')
        return JSON.parse(sanitizedData)
      }
      dispatch(
        load({
          fileName,
          data: sanitize(data),
          color,
          fontSizePercentage,
          categoriesCount,
          wineCategoriesCount,
          philosophyPositionOnLeft,
          hideFooterOnVerso
        })
      )
    }
  }
  const handleClick = () => {
    if (!hiddenFileInput.current) return
    hiddenFileInput.current.click()
  }

  // check if color is valid
  useEffect(() => {
    const isValidColor = /^#[0-9A-F]{6}$/i.test(color)
    if (!isValidColor) {
      setWarningColor('Couleur invalide')
    } else {
      setWarningColor('')
    }
  }, [color])

  if (menu.isLoading) {
    return <></>
  }
  if (menu.isLoaded) {
    return (
      <div className="absolute top-[20px] right-[30px] flex items-center justify-center text-gray-500 text-sm">
        <button onClick={() => dispatch(reset())}>Revenir en arrière</button>
      </div>
    )
  }
  const isValid = !!warningColor
  return (
    <div className="h-full flex  flex-col overflow-auto items-center justify-center">
      <>
        <input
          ref={hiddenFileInput}
          type="file"
          id="feedback_file"
          name="feedback_file"
          accept="application/JSON"
          onChange={handleUpload}
          style={{ display: 'none' }}
        />

        {withOptions ? (
          <div className="bg-white p-7 rounded-md shadow-sm ">
            <div className="">
              <div className="flex items-center">
                <div>
                  <label htmlFor="color">Menu Main Color</label>
                  <input
                    id="color"
                    type="text"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                    placeholder="#123456"
                    className="w-full max-w-xl rounded-md px-5 py-3  border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                  />
                </div>
                <input
                  type="color"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                  placeholder="Copier-coller la couleur du menu"
                  className=" h-14   mt-6 ml-2 border-none cursor-pointer"
                />
              </div>
              <p className="mt-2 text-center text-xs text-red-400">{warningColor}</p>

              <div className="mt-6">
                <label htmlFor="color">Font size transformation (%)</label>
                <input
                  id="fontSizeNumber"
                  type="number"
                  value={fontSizePercentage}
                  onChange={(e) => setFontSizePercentage(Number(e.target.value))}
                  placeholder="100"
                  className="w-full max-w-xl rounded-md px-5 py-3  border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                />
              </div>

              <div className="mt-6">
                <label htmlFor="color">
                  RECTO - Number of Food categories on the <strong>Left</strong>
                </label>
                <input
                  id="categoriesNumber"
                  type="number"
                  value={categoriesCount}
                  onChange={(e) => setCategoriesCount(Number(e.target.value))}
                  placeholder="3"
                  className="w-full max-w-xl rounded-md px-5 py-3  border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                />
              </div>

              <div className="mt-6">
                <label htmlFor="color">
                  VERSO - Number of Wine category(ies) on the <strong>Right</strong>
                </label>
                <input
                  id="wineCategoriesNumber"
                  type="number"
                  value={wineCategoriesCount}
                  onChange={(e) => setWineCategoriesCount(Number(e.target.value))}
                  placeholder="1"
                  className="w-full max-w-xl rounded-md px-5 py-3  border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                />
              </div>

              <div className="mt-6 flex items-center">
                <input
                  id="philosophyPosition"
                  type="checkbox"
                  checked={philosophyPositionOnLeft}
                  onChange={(e) => setPhilosophyPositionOnLeft(e.target.checked)}
                  placeholder="3"
                  className="rounded-md w-6 h-6 cursor-pointer"
                />
                <label htmlFor="philosophyPosition" className="ml-2 cursor-pointer">
                  Display the 'philosophy' insert on the left (on the right side by default)
                </label>
              </div>
              <div className="mt-6 flex items-center">
                <input
                  id="footerHiddingOption"
                  type="checkbox"
                  checked={hideFooterOnVerso}
                  onChange={(e) => setHideFooterOnVerso(e.target.checked)}
                  placeholder="3"
                  className="rounded-md w-6 h-6 cursor-pointer"
                />
                <label htmlFor="footerHiddingOption" className="ml-2 cursor-pointer">
                  Hide Footer on verso
                </label>
              </div>
            </div>
          </div>
        ) : null}
        <button
          onClick={handleClick}
          className={`mt-5 bg-black px-5 py-3  rounded-full text-white hover:bottom-2 relative ease-in duration-200 transition-all bottom-0 hover:drop-shadow-md ${
            isValid ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={isValid}
        >
          Upload your JSON file
        </button>
      </>
    </div>
  )
}

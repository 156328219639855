/* eslint-disable no-console */
import { useGlobal } from 'providers/hooks'
import { useState } from 'react'
import { ProductType } from 'type/menu'
import { VideoModal } from '../video'

export const Product = ({
  product,
  subCategory,
  idx,
  showCategory,
  hidden_products,
  filteredTags,
  isPerfectMatchFilter,
  removeTopBorder
}: {
  product: ProductType['product']
  subCategory?: ProductType['sub_category']
  idx?: number
  showCategory?: boolean
  hidden_products?: number[]
  filteredTags?: string[]
  isPerfectMatchFilter?: boolean
  removeTopBorder?: boolean
}) => {
  const global = useGlobal()
  const { showAllergens, showCalories } = global
  const [isOpen, setIsOpen] = useState(false)
  const isBestOfProduct = product?.attributes?.best_of_product
  const pairingProduct = product?.attributes?.pairing_product
  const marketingTags = product?.attributes?.tags.map((tag) => tag.marketing_title)
  const tags = product?.attributes?.tags.map((tag) => tag.title)
  const allTags = [...marketingTags, ...tags]
  const matchedTagsFilter = filteredTags?.filter((tag) => allTags.includes(tag))
  const showCategoryTitle = showCategory && subCategory?.attributes?.title

  const thereIsSubCategory = subCategory?.attributes?.title

  const closeModal = () => {
    setIsOpen(false)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const isVideo = product?.attributes?.is_video_product
  const image = product?.attributes?.image
  const type = product?.attributes?.type?.replace('-star', '*')

  const hideProduct = hidden_products?.includes(+product.attributes.product_id)
  const isPairingProductHidden = pairingProduct && hidden_products?.includes(pairingProduct.id)

  if (isVideo) {
    return (
      <>
        <VideoModal isOpen={isOpen} closeModal={closeModal} content={product?.attributes?.video} />
        <div className="w-full border-t border-primary">
          <img
            src={product?.attributes?.gif}
            alt="gif"
            className="mx-auto my-4 cursor-pointer w-80"
            onClick={openModal}
          />
        </div>
      </>
    )
  }

  const subcategoryAndHiddenAndNoDescription =
    thereIsSubCategory && !subCategory?.attributes?.description && hideProduct

  const subcategoryAndNoHiddenAndDescription = thereIsSubCategory && subCategory?.attributes?.description && hideProduct

  return (
    <div
      className={`${removeTopBorder || hideProduct ? '' : 'border-t'} border-primary ${!isPerfectMatchFilter && idx === 0 && 'border-none'} ${showCategoryTitle && !subcategoryAndHiddenAndNoDescription ? 'pb-[25px]' : hideProduct || !product.attributes.title ? '' : 'py-[25px] -mt-1'} ${pairingProduct && !isPairingProductHidden && 'pb-[15px]'}`}
    >
      {showCategoryTitle && (
        <div>
          <p
            className={`${hideProduct ? 'border-t border-primary' : ''} uppercase text-[18px] py-[12px] align-middle leading-4 mt-[5px] font-mammaTitle ${thereIsSubCategory && !subCategory?.attributes?.description && 'border-primary'} ${!subcategoryAndHiddenAndNoDescription && 'border-b mb-[25px] border-primary '} ${subcategoryAndNoHiddenAndDescription && 'border-b mb-[0px] border-primary '}`}
          >
            {subCategory?.attributes?.title}
          </p>
          {subCategory?.attributes?.description && (
            <p className="py-[20px] border-primary font-prestige whitespace-pre-wrap text-[12px] leading-[12px]">
              {subCategory?.attributes?.description}
            </p>
          )}
        </div>
      )}
      {!hideProduct && (
        <>
          <div
            className={`flex items-center justify-between px-[13px] ${isBestOfProduct ? 'flex-col-reverse items-center' : 'flex-row text-left'} ${pairingProduct && !isPairingProductHidden && 'pb-[15px]'}`}
          >
            <div className={`${isBestOfProduct ? 'flex flex-col items-center' : ''} w-full`}>
              {product.attributes.pre_title && (
                <p className="font-prestige text-[14px] mb-[15px] underline">{product.attributes?.pre_title}</p>
              )}
              <div className={isBestOfProduct ? 'flex flex-col gap-2 items-center flex-wrap' : 'gap-2'}>
                <p className="uppercase font-saaFD text-[15px] tracking-[0.5px] leading-[18px]">
                  {product.attributes?.title}
                </p>
                <div className="flex items-baseline gap-2 w-fit mb-[3px]">
                  {type && <p className="uppercase text-[11px] leading-[15px] font-prestige">({type})</p>}
                  {showCalories && product?.attributes?.kcal && (
                    <p className="uppercase text-[11px] leading-[15px] font-prestige">
                      {product?.attributes?.kcal} kcal
                    </p>
                  )}
                  <p className="text-[14px] leading-[18px] font-freehouse">{product?.attributes.price}</p>
                  {product?.attributes.price_mention && (
                    <p className="uppercase text-[11px] leading-[15px] font-prestige">
                      {product?.attributes.price_mention}
                    </p>
                  )}
                </div>
              </div>
              {product.attributes?.description && (
                <p className="text-[12px] leading-[13px] mt-2 font-mammaBody whitespace-pre-wrap">
                  {product.attributes?.description} {product?.attributes?.region && ` / ${product?.attributes?.region}`}
                </p>
              )}
              {showAllergens && product.attributes.allergens && (
                <div className="flex space-x-2 ">
                  <p className="underline italic font-mammaBody text-[11px] mt-2">{product.attributes.allergens}</p>
                </div>
              )}
            </div>
            {image && (
              <img
                className={`w-[100px] -ml-[10px] h-auto ${isBestOfProduct && 'h-[130px] w-auto'}`}
                src={image}
                alt="product"
              />
            )}
          </div>

          {matchedTagsFilter && matchedTagsFilter.length > 0 && (
            <div className="flex gap-2 w-full flex-wrap mt-1 px-[13px]">
              {matchedTagsFilter.map((tag, index) => (
                <p key={tag} className="italic font-mammaBody text-[11px] whitespace-nowrap">
                  <span className="underline">{tag}</span> {index !== matchedTagsFilter.length - 1 && '/'}
                </p>
              ))}
            </div>
          )}
          {pairingProduct && !isPairingProductHidden && (
            <div className="flex flex-col items-center pt-[15px] border-t-[1px] border-dashed border-primary">
              <p className="text-[12px] font-mammaBody tracking-normal	">{pairingProduct.title}</p>
              <p className="text-[16px] leading-[18px] tracking-wide -mt-1 font-freehouse">{pairingProduct.price}</p>
              {pairingProduct.image && <img className="w-[64px] h-auto" src={pairingProduct.image} alt="product" />}
            </div>
          )}
        </>
      )}
    </div>
  )
}

import en from './en.json'
import fr from './fr.json'
import es from './es.json'
import de from './de.json'
import nl from './nl.json'

const i18nextRessourrces = {
  en,
  fr,
  es,
  de,
  nl
}

export default i18nextRessourrces

import { Enum_Componentcategoriescategory_Gif_Placement } from 'api/graphql/types.generated'

export const renderPlacement = (imagePlacement: Enum_Componentcategoriescategory_Gif_Placement | null | undefined) => {
  if (!imagePlacement) return

  switch (imagePlacement) {
    case Enum_Componentcategoriescategory_Gif_Placement.TopLeft:
      return 'bottom-14 -left-2'
    case Enum_Componentcategoriescategory_Gif_Placement.TopRight:
      return 'bottom-14 -right-2'
    case Enum_Componentcategoriescategory_Gif_Placement.BottomLeft:
      return '-bottom-7 -left-2'
    case Enum_Componentcategoriescategory_Gif_Placement.BottomRight:
      return '-bottom-7 -right-2'
    default:
      break
  }
}

// @ts-nocheck
import { useAppDispatch, useAppSelector } from 'redux/hooks'

import { PdfRenderer } from 'components/render/pdfRenderer'
import { PDFViewer } from '@react-pdf/renderer'
import { loaded } from 'redux/slices/menu'
import { BeakerIcon } from '@heroicons/react/solid'

export const Preview = () => {
  const menu = useAppSelector((state) => state.menu)
  const dispatch = useAppDispatch()

  const trigger = () => {
    dispatch(loaded())
  }

  if (!menu?.json?.data) {
    return <></>
  }
  return (
    <>
      {menu.isLoading && (
        <div className="h-screen flex flex-col text-center justify-center">
          <p className="text-center w-full flex justify-center  text-green-500 text-lg">
            <span>
              <BeakerIcon className="h-7 w-7 mr-3 mt-[-5px]" />
            </span>
            Génération de la carte ...
          </p>
        </div>
      )}
      <div className={`text-center h-screen w-full mt-5 ${!menu.isLoaded ? 'hidden' : 'block'}`}>
        <div className="w-full p-[50px] h-screen sc overflow-auto">
          <PDFViewer>
            <PdfRenderer data={menu.json?.data} onRender={trigger} />
          </PDFViewer>
        </div>
      </div>
    </>
  )
}

import Linkify from 'react-linkify'
import { componentDecorator } from './decorator'

interface Props {
  allergens: string | undefined | null
  teams: string | undefined | null
  surpriseLink: string | undefined | null
  image?: string | undefined | null
}

export const Footer: React.FC<Props> = ({ image, allergens, teams, surpriseLink }) => {
  return (
    <Linkify componentDecorator={componentDecorator}>
      <div className="text-center text-primary font-mono text-[11px] pt-6 px-3 pb-28">
        <a href={surpriseLink || '#'} target="_blank" rel="noopener noreferrer">
          {image && <img className="max-w-[500px] w-full mx-auto" alt="" src={image} />}
        </a>

        {allergens && <p className="text-[11px] whitespace-pre-wrap leading-3 font-prestige mt-5 mb-2">{allergens}</p>}

        <div className="h-[1px] bg-primary my-7 mx-6" />

        {teams && <p className="text-[11px] whitespace-pre-wrap leading-3 font-prestige mt-5 mb-2">{teams}</p>}
      </div>
    </Linkify>
  )
}

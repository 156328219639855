// @ts-nocheck
import { Text, View, StyleSheet, Svg, G, Path } from '@react-pdf/renderer'
import { Menu } from 'type'

interface Props {
  data: Menu

  color?: string
}

export const Header: React.FC<Props> = ({ data, color }) => {
  const styles = StyleSheet.create({
    section: {
      margin: 10,
      position: 'relative',
      top: '0px',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '1px',
      borderBottom: `1px solid ${color}`,
      textAlign: 'left'
    },
    flex: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginTop: '3px',
      fontSize: '7.5pt'
    },
    line: {
      borderBottom: `2px solid ${color}`,
      width: '100%',
      marginTop: '5px'
    },
    title: {
      fontFamily: 'times',
      fontSize: '28pt',
      textTransform: 'uppercase',
      transform: 'scale(1, 1.3)',
      textDecorationColor: `${color}`,
      textDecorationStyle: 'solid',
      marginBottom: '-2pt'
    },
    adress: {
      fontFamily: 'prestige-bold',
      textTransform: 'uppercase',
      fontSize: '8pt',
      margin: '2pt 0'
    },
    description: {
      fontFamily: 'saa',
      textTransform: 'uppercase',
      fontSize: '6.5pt',
      marginTop: '1pt'
    },
    logo: {
      width: '35px',
      position: 'absolute',
      right: '5px',
      top: '0'
    }
  })

  return (
    <>
      <View style={styles.section}>
        <View style={styles.flex}>
          <Text style={styles.title}>{data.label}</Text>

          <Svg viewBox="0 0 28.6 29.58" width={33} h={33}>
            <G>
              <Path
                fill={color}
                d="M3,15.52c0.04-0.07,0.08-0.15,0.13-0.22c0.05-0.06,0.02-0.12-0.02-0.17c-0.04-0.06-0.02-0.1,0.01-0.16
			c0.05-0.1,0.1-0.19,0.19-0.27c0.06-0.06,0.06-0.19,0.11-0.27c0.07-0.11,0.08-0.23,0.12-0.34c0.05-0.1,0.1-0.22,0.21-0.28
			c0.01,0,0.01-0.01,0.01-0.01c0.06-0.12,0.18-0.21,0.21-0.35c0.04-0.24,0.2-0.42,0.31-0.63c0.01-0.03,0.05-0.05,0.08-0.07
			c0.03-0.02,0.05-0.05,0.06-0.08c0.02-0.09,0.04-0.18,0.09-0.26c0.03-0.05,0-0.09-0.03-0.13c-0.05-0.06-0.06-0.13-0.04-0.2
			c0.02-0.07,0.01-0.12-0.02-0.18c-0.05,0-0.06,0.05-0.08,0.08c-0.05,0.07-0.09,0.05-0.13-0.01c-0.03-0.04-0.06-0.09-0.09-0.13
			c-0.08-0.13-0.18-0.22-0.34-0.21c-0.02,0-0.05,0.01-0.06-0.02c-0.05-0.09-0.11-0.17-0.15-0.26c-0.07-0.15,0.03-0.28,0.19-0.27
			C3.88,11.09,4,11.1,4.11,11.16c0.07,0.04,0.15,0.06,0.22,0c0.14-0.13,0.3-0.09,0.46-0.05c0.23,0.06,0.47,0.1,0.7,0.03
			c0.08-0.02,0.15,0.02,0.22,0.02c0.17,0,0.34-0.03,0.51-0.02c0.03,0,0.05-0.01,0.08-0.03c0.06-0.03,0.12-0.04,0.18-0.02
			c0.08,0.03,0.16,0.03,0.24-0.01c0.08-0.04,0.15-0.01,0.21,0.04c0.11,0.09,0.23,0.08,0.36,0.05c0.01,0,0.02,0,0.03-0.01
			c0.18-0.14,0.39-0.09,0.59-0.06c0.05,0.01,0.1,0.03,0.14,0.07c0.08,0.08,0.16,0.16,0.25,0.22c0.04,0.02,0.06,0.07,0.07,0.11
			c0.01,0.09,0.06,0.15,0.14,0.2c0.08,0.05,0.13,0.13,0.19,0.21c0.03,0.03,0.05,0.08,0.08,0.1c0.13,0.1,0.12,0.22,0.09,0.36
			c-0.02,0.08-0.02,0.15,0.08,0.19c0.07,0.02,0.11,0.08,0.14,0.15c0.06,0.15,0.16,0.28,0.22,0.44c0.01,0.04,0.05,0.06,0.09,0.09
			c0.1,0.07,0.17,0.14,0.18,0.28c0.02,0.23,0.16,0.41,0.28,0.59c0.04,0.07,0.09,0.14,0.08,0.23c0,0.04,0.02,0.09,0.05,0.12
			c0.16,0.17,0.25,0.39,0.42,0.55c0.04,0.03,0.04,0.07,0.03,0.13c-0.03,0.09-0.02,0.21-0.12,0.24c-0.12,0.03-0.23-0.04-0.32-0.12
			c-0.02-0.02-0.04-0.06-0.07-0.07c-0.15-0.05-0.27-0.17-0.42-0.2c-0.1-0.02-0.25-0.02-0.32,0.04c-0.11,0.1-0.26,0.17-0.33,0.33
			c-0.06,0.13-0.14,0.25-0.23,0.37c-0.07,0.1-0.15,0.2-0.21,0.31c-0.03,0.07-0.06,0.13-0.12,0.18c-0.04,0.03-0.04,0.08-0.04,0.12
			c0,0.08-0.05,0.13-0.11,0.15c-0.08,0.03-0.08,0.09-0.07,0.15c0.03,0.15,0.03,0.16-0.1,0.23c-0.04,0.02-0.08,0.04-0.12,0.05
			c-0.14,0.04-0.2,0.17-0.12,0.29c0.05,0.08,0.03,0.12-0.03,0.17c-0.03,0.02-0.05,0.05-0.06,0.08c-0.03,0.07-0.07,0.09-0.14,0.12
			c-0.1,0.03-0.18,0.11-0.19,0.23c-0.01,0.08-0.01,0.16-0.13,0.15c-0.06,0-0.07-0.04-0.09-0.08c-0.02-0.04-0.03-0.05-0.08-0.04
			c-0.07,0.02-0.14,0.03-0.21,0.06c-0.11,0.06-0.21,0.05-0.3-0.03c-0.11-0.1-0.22-0.1-0.35-0.05c-0.04,0.02-0.08,0.03-0.11-0.03
			c-0.02-0.05-0.07-0.04-0.11-0.02c-0.18,0.09-0.38,0.1-0.58,0.09c-0.05,0-0.09-0.05-0.13-0.09c-0.07-0.06-0.13-0.09-0.21-0.02
			c-0.03,0.03-0.08,0.04-0.11-0.02c-0.06-0.14-0.17-0.22-0.31-0.29c-0.15-0.08-0.31-0.14-0.43-0.28C4.09,17.09,4,17.03,3.9,17
			c-0.1-0.03-0.18-0.08-0.22-0.17c-0.05-0.09-0.14-0.14-0.21-0.21c-0.09-0.09-0.14-0.18-0.13-0.31c0-0.07-0.03-0.13-0.09-0.16
			c-0.05-0.03-0.07-0.07-0.09-0.12C3.1,15.9,3.05,15.78,3,15.65C3,15.61,3,15.56,3,15.52z"
              />
              <Path
                fill={color}
                d="M14.85,5.5c-0.08,0.04-0.08,0.12-0.1,0.19c-0.02,0.06-0.05,0.09-0.1,0.11c-0.12,0.06-0.14,0.08-0.11,0.21
			c0.01,0.07-0.01,0.13-0.06,0.18c-0.05,0.06-0.09,0.13-0.12,0.2c-0.05,0.11-0.1,0.21-0.2,0.3c-0.08,0.08-0.14,0.18-0.22,0.26
			c-0.07,0.07-0.05,0.19-0.07,0.29c-0.01,0.05-0.03,0.1-0.08,0.12c-0.17,0.07-0.22,0.23-0.28,0.38c-0.04,0.1-0.09,0.21-0.08,0.33
			c0,0.07-0.05,0.12-0.09,0.17c-0.07,0.07-0.11,0.15-0.15,0.24c-0.04,0.11-0.1,0.2-0.2,0.27c-0.08,0.05-0.13,0.13-0.15,0.22
			c-0.03,0.1-0.07,0.2-0.16,0.27c-0.02,0.02-0.04,0.04-0.04,0.06c-0.01,0.14-0.09,0.26-0.1,0.4c-0.01,0.05-0.02,0.11-0.07,0.14
			c-0.13,0.07-0.18,0.22-0.29,0.31c-0.03,0.03-0.04,0.07-0.05,0.11c-0.03,0.15-0.11,0.27-0.21,0.38c-0.05,0.06-0.08,0.14-0.1,0.22
			c-0.02,0.09-0.06,0.16-0.14,0.21c-0.09,0.07-0.16,0.16-0.25,0.23c-0.05,0.04-0.03,0.11-0.05,0.16c-0.03,0.12-0.18,0.19-0.28,0.12
			c-0.09-0.06-0.17-0.12-0.27-0.18c-0.05-0.03-0.06-0.07-0.08-0.11c-0.03-0.07-0.08-0.1-0.15-0.1c-0.07,0-0.12-0.02-0.16-0.06
			c-0.11-0.08-0.23-0.14-0.36-0.19c-0.12-0.04-0.22-0.11-0.28-0.23c-0.02-0.04-0.06-0.06-0.1-0.06c-0.1-0.01-0.21-0.02-0.31-0.02
			c-0.07,0-0.14-0.02-0.19-0.08c-0.08-0.08-0.17-0.16-0.27-0.22c-0.09-0.05-0.12-0.16-0.19-0.23c-0.04-0.05-0.07-0.1-0.15-0.05
			c-0.05,0.03-0.08-0.01-0.11-0.05C8.44,9.94,8.38,9.9,8.3,9.92C8.15,9.95,8.05,9.87,7.96,9.77C7.88,9.7,7.8,9.67,7.7,9.64
			C7.54,9.6,7.39,9.5,7.24,9.43C7.23,9.42,7.22,9.41,7.22,9.4C7.15,9.25,7.03,9.21,6.88,9.19C6.74,9.17,6.71,9.08,6.78,8.97
			c0.07-0.1,0.13-0.19,0.15-0.32c0.01-0.1,0.09-0.19,0.18-0.26c0.04-0.03,0.07-0.08,0.07-0.13c0-0.11,0.05-0.2,0.08-0.3
			C7.28,7.93,7.3,7.89,7.34,7.86C7.5,7.76,7.56,7.6,7.59,7.43c0.02-0.1,0.06-0.17,0.16-0.2c0.09-0.03,0.15-0.11,0.14-0.2
			c-0.01-0.12,0.04-0.21,0.1-0.3C8,6.72,8.01,6.72,8.01,6.71c0.03-0.18,0.17-0.3,0.26-0.45c0.03-0.06,0.08-0.1,0.13-0.14
			c0.07-0.07,0.12-0.16,0.15-0.26c0.03-0.1,0.09-0.19,0.14-0.29C8.7,5.54,8.75,5.56,8.78,5.55c0.06-0.01,0.1-0.02,0.08-0.1
			c-0.01-0.04,0.01-0.08,0.05-0.1C9,5.32,9.02,5.27,8.97,5.19C8.96,5.16,8.96,5.12,9.01,5.1C9.1,5.07,9.16,5,9.22,4.93
			c0.09-0.12,0.23-0.18,0.36-0.25c0.04-0.02,0.07-0.05,0.08-0.09c0.02-0.07,0.07-0.1,0.11-0.15c0.1-0.11,0.21-0.14,0.35-0.13
			c0.1,0,0.2-0.05,0.29-0.1c0.04-0.03,0.08-0.03,0.13-0.02c0.06,0.02,0.13,0.02,0.19-0.03c0.05-0.04,0.11-0.03,0.15,0.02
			c0.07,0.07,0.16,0.09,0.26,0.07c0.03-0.01,0.06-0.01,0.09-0.01c0.1,0.01,0.19-0.02,0.28-0.06c0.15-0.07,0.31-0.06,0.46-0.02
			c0.07,0.02,0.13,0.02,0.19-0.02c0.09-0.05,0.17-0.05,0.26,0.01c0.02,0.01,0.04,0.03,0.05,0.03c0.15-0.04,0.29,0.06,0.42,0.05
			c0.15-0.01,0.3,0.01,0.44-0.04c0.03-0.01,0.07-0.01,0.09-0.01c0.1,0.03,0.19,0,0.28-0.03c0.08-0.03,0.16,0,0.23,0.03
			c0.17,0.09,0.33,0.06,0.48-0.02c0.12-0.06,0.22-0.09,0.34,0.01c0.09,0.07,0.2,0.08,0.31,0.03c0.05-0.03,0.11-0.02,0.15,0.01
			c0.07,0.04,0.15,0.05,0.22,0.01c0.17-0.08,0.35-0.04,0.52-0.06c0.13-0.01,0.22,0.09,0.32,0.14c0.07,0.03,0.04,0.18-0.04,0.21
			c-0.05,0.02-0.11,0.01-0.16,0.03c-0.15,0.04-0.3,0.1-0.38,0.25c-0.02,0.03-0.05,0.07-0.08,0.08c-0.08,0.03-0.14,0.08-0.17,0.16
			c-0.01,0.05-0.06,0.04-0.1,0.04c-0.13-0.02-0.22,0.08-0.33,0.12c-0.04,0.01-0.04,0.06-0.04,0.09C15,5.33,14.97,5.39,14.95,5.44
			c0,0.01,0,0.01,0,0.02l0,0c-0.01,0-0.01,0-0.02,0c-0.01,0-0.02,0-0.02,0c-0.01,0-0.02,0.01-0.02,0.02c-0.01,0-0.02,0.01-0.02,0.02
			l0,0C14.87,5.5,14.86,5.5,14.85,5.5z"
              />
              <Path
                fill={color}
                d="M23.28,11.69c-0.01,0.06,0.02,0.12,0.06,0.18c0.08,0.12,0.16,0.24,0.27,0.34c0.04,0.03,0.05,0.08,0.06,0.13
			c0.01,0.05,0.02,0.12,0.07,0.15c0.13,0.09,0.2,0.22,0.31,0.32c0.05,0.04,0.08,0.1,0.07,0.16c-0.01,0.07,0.02,0.13,0.06,0.18
			c0.08,0.1,0.13,0.21,0.19,0.32c0.03,0.05,0.06,0.1,0.11,0.12c0.06,0.02,0.09,0.07,0.1,0.13c0.02,0.1,0.06,0.2,0.13,0.28
			c0.11,0.13,0.17,0.29,0.25,0.43c0.05,0.08,0.08,0.17,0.15,0.24c0.08,0.08,0.09,0.2,0.11,0.3c0.01,0.07,0.04,0.12,0.09,0.16
			c0.05,0.04,0.06,0.09,0.05,0.15c-0.03,0.11-0.01,0.2,0.07,0.29c0.12,0.15,0.19,0.32,0.17,0.51c-0.02,0.13-0.07,0.26-0.16,0.35
			c-0.04,0.05-0.06,0.08-0.01,0.14c0.13,0.16,0.1,0.23-0.03,0.36c-0.05,0.05-0.07,0.11-0.05,0.17c0.02,0.14-0.03,0.24-0.15,0.3
			c-0.08,0.05-0.14,0.12-0.15,0.22c-0.01,0.06-0.05,0.1-0.1,0.12c-0.08,0.03-0.11,0.1-0.11,0.17c0,0.08-0.03,0.13-0.09,0.17
			c-0.04,0.03-0.07,0.07-0.03,0.13c0.04,0.05,0.01,0.09-0.04,0.11c-0.19,0.11-0.27,0.29-0.33,0.49c-0.03,0.11-0.11,0.18-0.15,0.29
			c-0.01,0.04-0.06,0.04-0.1,0.04c-0.1,0.01-0.12,0.08-0.14,0.16c-0.02,0.14-0.02,0.29-0.07,0.42c-0.04,0.11-0.09,0.21-0.21,0.26
			c-0.04,0.02-0.05,0.05-0.06,0.09c-0.03,0.17-0.11,0.31-0.24,0.42c-0.09,0.07-0.13,0.19-0.23,0.26c-0.02,0.01-0.02,0.05-0.02,0.07
			c0.01,0.09,0,0.17-0.09,0.22c-0.03,0.02-0.03,0.06-0.02,0.1c0.03,0.06,0,0.11-0.03,0.16c-0.08,0.09-0.13,0.21-0.24,0.28
			c-0.07,0.04-0.04,0.11-0.04,0.17c0,0.14-0.04,0.18-0.18,0.18c-0.08,0-0.12-0.04-0.09-0.12c0.04-0.11,0.03-0.2-0.02-0.3
			c-0.03-0.07-0.03-0.15-0.01-0.21c0.04-0.11,0.02-0.23,0.04-0.35c0.01-0.04-0.01-0.09-0.03-0.13c-0.05-0.07-0.07-0.15-0.04-0.24
			c0.01-0.04,0.01-0.07-0.02-0.1c-0.05-0.04-0.06-0.09-0.02-0.15c0.03-0.05,0.02-0.09-0.02-0.12c-0.07-0.06-0.1-0.13-0.13-0.22
			c-0.04-0.14-0.14-0.25-0.24-0.35c-0.11-0.13-0.19-0.28-0.25-0.43c-0.06-0.14-0.15-0.26-0.26-0.37c-0.03-0.03-0.04-0.06-0.05-0.1
			c-0.02-0.18-0.08-0.35-0.23-0.47c-0.04-0.03-0.07-0.08-0.09-0.13c-0.04-0.11-0.09-0.2-0.2-0.25c-0.08-0.04-0.1-0.13-0.14-0.2
			c-0.1-0.21-0.23-0.42-0.37-0.61c-0.03-0.04-0.05-0.09-0.06-0.15c-0.02-0.18-0.09-0.33-0.26-0.42c-0.05-0.03-0.07-0.07-0.1-0.14
			c-0.07-0.19-0.18-0.37-0.3-0.53c-0.07-0.09-0.14-0.18-0.22-0.26c-0.04-0.05-0.04-0.1-0.04-0.15c0-0.06,0-0.11-0.07-0.13
			c-0.02-0.01-0.05-0.03-0.06-0.06c-0.08-0.19-0.23-0.35-0.27-0.56c-0.01-0.04-0.02-0.08-0.06-0.1c-0.08-0.05-0.12-0.13-0.14-0.22
			c-0.01-0.04-0.03-0.08-0.05-0.11c-0.1-0.11-0.07-0.24-0.07-0.36c0-0.06,0.05-0.11,0.12-0.11c0.04,0,0.09,0,0.1-0.03
			c0.05-0.1,0.14-0.12,0.23-0.13c0.08-0.01,0.14-0.07,0.2-0.12c0.13-0.09,0.29-0.14,0.39-0.27c0.02-0.03,0.05-0.05,0.09-0.06
			c0.14-0.04,0.26-0.12,0.34-0.25c0.02-0.04,0.06-0.04,0.09-0.03c0.06,0.02,0.11,0,0.15-0.04c0.11-0.08,0.23-0.14,0.33-0.24
			c0.05-0.06,0.13-0.02,0.2-0.03c0.02,0,0.04,0.01,0.06,0.01c0.07-0.01,0.11-0.03,0.06-0.11c-0.05-0.07,0.01-0.12,0.06-0.15
			c0.09-0.05,0.19-0.09,0.26-0.17c0,0,0.01-0.01,0.02-0.01c0.15,0.01,0.24-0.1,0.36-0.16c0.05-0.03,0.1-0.05,0.15-0.09
			c0.13-0.12,0.29-0.19,0.44-0.26c0.06-0.03,0.11-0.08,0.16-0.13c0.1-0.1,0.22-0.17,0.33-0.27c0.07-0.06,0.18,0.01,0.22,0.11
			c0.03,0.07,0.05,0.13,0.16,0.11C23.24,11.44,23.29,11.55,23.28,11.69z"
              />
              <Path
                fill={color}
                d="M12.31,23.87c-0.12-0.04-0.24,0-0.36-0.03c-0.02-0.01-0.05-0.01-0.07,0c-0.21,0.09-0.44-0.02-0.65,0.03
			c-0.04,0.01-0.1,0.01-0.13,0.04c-0.06,0.04-0.09,0.03-0.13-0.02c-0.1-0.1-0.19-0.11-0.32-0.04c-0.1,0.05-0.19,0.08-0.3,0.03
			c-0.04-0.02-0.08,0.01-0.11,0c-0.18-0.02-0.36,0.01-0.54-0.01c-0.14-0.02-0.26-0.04-0.39,0.01c-0.09,0.04-0.2,0.02-0.29-0.01
			c-0.23-0.07-0.46-0.09-0.69-0.05c-0.09,0.01-0.17-0.01-0.24-0.07c-0.09-0.08-0.2-0.1-0.32-0.06c-0.03,0.01-0.05,0.02-0.08,0
			c-0.07-0.06-0.15-0.07-0.24-0.06c-0.07,0.01-0.12-0.02-0.17-0.07c-0.15-0.16-0.32-0.26-0.54-0.31c-0.05-0.01-0.1-0.05-0.12-0.1
			c-0.04-0.08-0.1-0.15-0.18-0.18c-0.07-0.02-0.07-0.08-0.08-0.13c-0.01-0.12-0.07-0.2-0.17-0.26c-0.09-0.05-0.08-0.11-0.06-0.19
			c0.01-0.03,0.02-0.05,0.03-0.08c0.04-0.08,0.02-0.14-0.06-0.18c-0.17-0.09-0.28-0.22-0.31-0.41c-0.01-0.07-0.04-0.14-0.1-0.19
			c-0.14-0.12-0.2-0.28-0.25-0.44c-0.03-0.12-0.1-0.23-0.17-0.32c-0.07-0.09-0.1-0.19-0.11-0.29c-0.01-0.1-0.03-0.2-0.15-0.24
			c-0.05-0.01-0.07-0.06-0.08-0.1c-0.03-0.13-0.08-0.25-0.21-0.33c-0.1-0.06-0.12-0.18-0.13-0.3c0-0.05-0.01-0.1-0.02-0.15
			c-0.01-0.08-0.05-0.13-0.12-0.14c-0.12-0.02-0.17-0.12-0.18-0.23c0-0.03,0-0.06,0-0.09c0.01-0.08-0.02-0.14-0.1-0.16
			c-0.08-0.02-0.09-0.07-0.07-0.14c0.03-0.08,0.02-0.15-0.06-0.19c-0.06-0.03-0.07-0.09-0.07-0.15c0-0.08-0.02-0.15-0.09-0.2
			C3.86,18.04,3.83,18,3.85,17.95c0.02-0.05,0.07-0.04,0.12-0.04c0.03,0,0.06,0,0.09-0.01c0.03-0.01,0.08,0,0.09,0.03
			c0.05,0.11,0.16,0.15,0.22,0.25c0.05,0.08,0.08,0.07,0.13-0.01c0.02-0.03,0.04-0.06,0.06-0.02c0.06,0.1,0.16,0.12,0.26,0.15
			c0.09,0.03,0.19,0.06,0.28,0.11c0.03,0.02,0.07,0.03,0.11,0.03c0.21-0.02,0.39,0.1,0.58,0.14c0.11,0.02,0.2,0.02,0.3-0.03
			c0.12-0.07,0.25-0.11,0.39-0.07c0.03,0.01,0.07,0.01,0.11-0.01c0.04-0.02,0.09-0.02,0.12,0c0.14,0.07,0.28,0.05,0.43,0.05
			c0.04,0,0.08-0.01,0.11-0.02c0.11-0.04,0.22-0.04,0.31,0.04c0.12,0.11,0.26,0.08,0.38,0.03c0.11-0.05,0.2-0.07,0.3,0.01
			c0.05,0.04,0.11,0.02,0.16-0.02c0.08-0.07,0.16-0.07,0.26-0.04c0.1,0.03,0.2,0.03,0.3-0.02c0.14-0.06,0.26,0,0.37,0.07
			c0.04,0.03,0.07,0.02,0.11,0c0.11-0.08,0.25-0.08,0.37-0.12c0.06-0.02,0.13,0,0.18,0.05c0.02,0.03,0.05,0.05,0.08,0.06
			c0.05,0.02,0.1,0.03,0.12-0.04c0.02-0.07,0.07-0.1,0.13-0.06c0.18,0.12,0.38,0.11,0.59,0.11c0.17,0,0.33-0.06,0.49-0.05
			c0.09,0,0.16,0.03,0.25,0.05c0.15,0.05,0.31,0.05,0.46-0.02c0.08-0.04,0.14-0.02,0.22,0.01c0.11,0.04,0.21,0.09,0.29,0.18
			c0.03,0.04,0.05,0.08-0.01,0.12c-0.12,0.09-0.11,0.21-0.07,0.33c0.03,0.09,0.05,0.17,0,0.26c-0.03,0.05-0.03,0.12-0.01,0.17
			c0.07,0.19,0.08,0.37,0.03,0.56c-0.03,0.09-0.01,0.18,0.05,0.26c0.03,0.04,0.02,0.07,0,0.1c-0.09,0.17-0.11,0.33-0.02,0.5
			c0.02,0.04,0.02,0.08,0.01,0.11c-0.04,0.18-0.01,0.36-0.02,0.54c0,0.09,0.01,0.18-0.03,0.27c-0.02,0.04,0,0.09,0.01,0.13
			c0.02,0.19,0.04,0.38-0.06,0.56c-0.03,0.05-0.04,0.11,0.04,0.15c0.06,0.03,0.07,0.08,0.03,0.13c-0.1,0.18-0.08,0.36,0.02,0.53
			c0.04,0.07,0.02,0.12-0.03,0.17c-0.06,0.07-0.13,0.12-0.17,0.2C12.41,23.87,12.35,23.85,12.31,23.87z"
              />
              <Path
                fill={color}
                d="M19.66,11.5c-0.11,0-0.2-0.06-0.3-0.06c-0.15,0-0.3-0.02-0.45-0.03c-0.07,0-0.14,0.04-0.21,0.06
			c-0.09,0.04-0.17,0.03-0.26,0c-0.22-0.07-0.44-0.15-0.67-0.06c-0.11,0.04-0.21,0.06-0.32,0c-0.04-0.02-0.09-0.01-0.14-0.01
			c-0.06,0.01-0.12,0.01-0.17-0.02c-0.02-0.01-0.06-0.02-0.08-0.01c-0.25,0.1-0.48-0.01-0.71-0.06c-0.13-0.03-0.25-0.01-0.36,0.07
			c-0.08,0.06-0.15,0.08-0.23,0.01c-0.06-0.06-0.11-0.03-0.15,0.02c-0.07,0.07-0.09,0.03-0.11-0.04c0-0.01,0-0.01,0-0.02
			c-0.03-0.12-0.03-0.12-0.15-0.09c-0.13,0.04-0.22,0.01-0.27-0.1c-0.04-0.07-0.02-0.12,0.06-0.11c0.22,0.03,0.37-0.12,0.56-0.17
			c0.07-0.02,0.11-0.07,0.12-0.14c0.01-0.08,0.05-0.11,0.13-0.13c0.19-0.04,0.24-0.2,0.11-0.35c-0.06-0.07-0.09-0.14-0.1-0.23
			c-0.01-0.1-0.05-0.18-0.15-0.22c-0.08-0.03-0.13-0.1-0.13-0.18c-0.01-0.05-0.02-0.1-0.05-0.13c-0.09-0.13-0.09-0.29-0.18-0.42
			c-0.01-0.01-0.01-0.03-0.01-0.04c0.03-0.13-0.05-0.16-0.15-0.18c-0.09-0.01-0.12-0.07-0.11-0.16c0-0.02,0-0.04,0-0.07
			c0-0.18,0-0.19-0.17-0.24c-0.06-0.02-0.09-0.05-0.13-0.1c-0.11-0.16-0.14-0.36-0.21-0.53c-0.06-0.16-0.06-0.32-0.08-0.48
			c-0.02-0.13,0.03-0.25,0.12-0.36c0.07-0.09,0.13-0.17,0.25-0.19c0.08-0.02,0.07-0.1,0.09-0.16c0.01-0.05,0-0.09,0-0.14
			c0-0.08,0.03-0.14,0.1-0.18c0.16-0.1,0.28-0.23,0.32-0.41c0.03-0.13,0.15-0.16,0.24-0.23c0.03-0.02,0.07-0.04,0.09-0.07
			c0.16-0.24,0.36-0.41,0.66-0.44c0.09-0.01,0.13-0.06,0.15-0.14c0.01-0.07,0.05-0.1,0.12-0.1c0.13,0,0.26-0.04,0.39-0.04
			c0.04,0,0.09-0.03,0.11-0.06c0.1-0.13,0.23-0.13,0.37-0.08c0.08,0.03,0.15,0.03,0.2-0.04c0.02-0.03,0.05-0.03,0.09-0.02
			C18,4.71,18.16,4.69,18.32,4.7c0.08,0,0.14,0.01,0.17,0.1c0.02,0.06,0.07,0.1,0.15,0.06c0.08-0.04,0.16,0.02,0.16,0.11
			c0,0.08,0.05,0.13,0.12,0.17c0.06,0.03,0.09,0.07,0.12,0.13c0.08,0.15,0.17,0.29,0.21,0.46c0.03,0.1,0.12,0.16,0.23,0.21
			c0.13,0.06,0.27,0.13,0.3,0.3c0.01,0.04,0.04,0.09,0.08,0.1c0.08,0.04,0.11,0.11,0.11,0.19c-0.01,0.22,0.12,0.36,0.26,0.49
			c0.09,0.09,0.15,0.19,0.22,0.28c0.1,0.12,0.19,0.25,0.32,0.34c0.05,0.04,0.09,0.03,0.13-0.01c0.12-0.13,0.13-0.13,0.27-0.02
			c0.03,0.02,0.05,0.01,0.07-0.01c0.04-0.05,0.1-0.07,0.16-0.08c0.09-0.02,0.18-0.06,0.24-0.13c0.14-0.16,0.32-0.18,0.5-0.07
			c0.11,0.06,0.11,0.22,0,0.29c-0.01,0.01-0.02,0.01-0.03,0.02c-0.2,0.04-0.25,0.2-0.25,0.36c0,0.15-0.07,0.25-0.15,0.35
			c-0.14,0.19-0.26,0.4-0.39,0.6c-0.03,0.04-0.01,0.08,0,0.13c0.04,0.14,0.03,0.18-0.09,0.26c-0.1,0.06-0.17,0.15-0.26,0.22
			c-0.09,0.08-0.05,0.2-0.07,0.3c-0.02,0.12-0.08,0.22-0.17,0.29c-0.06,0.05-0.07,0.1-0.07,0.17c0,0.19-0.02,0.33-0.2,0.4
			c-0.07,0.02-0.12,0.07-0.17,0.12c-0.06,0.05-0.07,0.12-0.06,0.19c0.03,0.15-0.04,0.27-0.18,0.33c-0.11,0.04-0.25,0.06-0.33,0.17
			C19.72,11.51,19.68,11.5,19.66,11.5z"
              />
              <Path
                fill={color}
                d="M17.03,17.81c-0.01,0.05,0.02,0.11,0.05,0.17c0.08,0.15,0.12,0.32,0.03,0.48c-0.03,0.06-0.03,0.11,0.02,0.15
			c0.03,0.02,0.05,0.05,0.07,0.08c0.06,0.11,0.13,0.12,0.24,0.08c0.09-0.04,0.21,0,0.31-0.03c0.1-0.03,0.2-0.03,0.3,0.01
			c0.08,0.03,0.17-0.03,0.25,0c0.01,0,0.03,0,0.04,0.01c0.11,0.09,0.22,0.12,0.34,0.01c0.02-0.02,0.06-0.02,0.08-0.01
			c0.09,0.04,0.17,0.01,0.25-0.05c0.06-0.04,0.13-0.05,0.2-0.02c0.1,0.05,0.19,0.04,0.3,0c0.11-0.04,0.24-0.06,0.36-0.08
			c0.02,0,0.06,0.01,0.08,0.02c0.18,0.12,0.39,0.11,0.59,0.15c0.16,0.03,0.33,0.07,0.45,0.21c0.04,0.05,0.05,0.11,0.04,0.17
			c-0.01,0.09,0.01,0.14,0.1,0.19c0.11,0.06,0.17,0.17,0.17,0.3c0,0.13,0.03,0.24,0.12,0.34c0.05,0.06,0.09,0.13,0.11,0.2
			c0.02,0.07,0.06,0.14,0.1,0.2c0.06,0.09,0.09,0.18,0.09,0.28c-0.01,0.1,0.04,0.2,0.06,0.29c0.03,0.16,0.02,0.32,0.05,0.47
			c0.01,0.04-0.04,0.21-0.07,0.24c-0.03,0.04-0.04,0.1-0.02,0.15c0.04,0.12,0.05,0.24,0.05,0.37c0,0.06-0.02,0.12-0.05,0.17
			c-0.07,0.11-0.14,0.22-0.12,0.37c0.01,0.05-0.04,0.06-0.07,0.07c-0.05,0.02-0.07,0.05-0.1,0.1c-0.1,0.21-0.22,0.43-0.47,0.51
			c-0.1,0.03-0.19,0.08-0.22,0.2c-0.03,0.11-0.11,0.14-0.22,0.1c-0.11-0.04-0.21-0.06-0.31,0.02c-0.01,0.01-0.03,0.02-0.06,0.01
			c-0.1-0.03-0.21-0.01-0.31,0.03c-0.11,0.04-0.21,0.05-0.32,0.04c-0.04,0-0.1-0.01-0.13,0.03c-0.1,0.11-0.22,0.06-0.33,0.04
			c-0.09-0.01-0.18-0.04-0.27,0c-0.01,0.01-0.03,0.01-0.05,0c-0.13-0.03-0.25,0-0.37,0.06c-0.13,0.05-0.26,0-0.39-0.01
			c-0.09-0.01-0.16,0-0.23,0.05c-0.07,0.05-0.13,0.09-0.22,0.02c-0.07-0.05-0.11,0-0.14,0.06c-0.05,0.1-0.07,0.2-0.15,0.28
			c-0.03,0.03-0.02,0.06,0,0.1c0.03,0.08,0.06,0.16,0.1,0.25c0.01,0.04,0.02,0.07-0.02,0.09c-0.13,0.07-0.13,0.17-0.1,0.29
			c0.05,0.19-0.07,0.35-0.26,0.36c-0.07,0.01-0.11-0.03-0.12-0.1c-0.03-0.17-0.08-0.32-0.22-0.43c-0.04-0.03-0.06-0.08-0.06-0.13
			c-0.01-0.18-0.09-0.32-0.26-0.41c-0.11-0.06-0.15-0.17-0.19-0.27c-0.06-0.15-0.19-0.26-0.24-0.42c0-0.01-0.01-0.02-0.02-0.02
			c-0.13-0.04-0.15-0.14-0.17-0.26c-0.01-0.05-0.06-0.09-0.08-0.13c-0.05-0.09-0.1-0.18-0.12-0.27c-0.04-0.13-0.11-0.23-0.23-0.3
			c-0.09-0.04-0.13-0.11-0.15-0.21c-0.02-0.1-0.07-0.18-0.18-0.21c-0.11-0.03-0.16-0.14-0.23-0.23c-0.11-0.16-0.08-0.33-0.04-0.5
			c0.03-0.16,0.06-0.31,0.2-0.41c0.02-0.02,0.04-0.05,0.03-0.07c-0.03-0.16,0.07-0.28,0.11-0.41c0.03-0.1,0.12-0.19,0.2-0.27
			c0.08-0.09,0.17-0.17,0.22-0.28c0.05-0.11,0.08-0.2,0.04-0.32c-0.02-0.07-0.02-0.15,0.1-0.15c0.07,0,0.13-0.04,0.15-0.12
			c0.01-0.04,0.05-0.07,0.1-0.08c0.16-0.06,0.19-0.22,0.24-0.35c0.03-0.07,0.03-0.15,0.08-0.21c0.02-0.03,0.05-0.07,0.05-0.11
			c0.02-0.09,0.07-0.15,0.15-0.18c0.06-0.03,0.08-0.07,0.04-0.12c-0.04-0.05-0.03-0.1-0.01-0.15c0.06-0.13,0.09-0.27,0.23-0.35
			c0.05-0.03,0.06-0.09,0.07-0.14c0.03-0.15,0.14-0.2,0.26-0.26c0.05-0.03,0.14,0.03,0.15,0.11C17.04,17.72,17.03,17.76,17.03,17.81
			z"
              />
              <Path
                fill={color}
                d="M14.87,5.52c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0c0,0.01,0,0.01,0,0.02l0,0L14.87,5.52
			C14.88,5.52,14.87,5.52,14.87,5.52z"
              />
              <Path
                fill={color}
                d="M14.89,5.54c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0c0,0.01,0,0.01,0,0.02
			C14.9,5.54,14.9,5.54,14.89,5.54z"
              />
              <Path
                fill={color}
                d="M14.95,5.54c0.01,0,0.01,0,0.02,0c0,0.01-0.01,0.02-0.02,0.02c0,0,0,0,0,0C14.95,5.55,14.95,5.54,14.95,5.54
			L14.95,5.54z"
              />
              <Path
                fill={color}
                d="M14.89,5.56c0.01,0,0.01,0,0.02,0c0,0.01,0,0.01,0,0.02C14.9,5.57,14.9,5.57,14.89,5.56z"
              />
              <Path
                fill={color}
                d="M14.93,5.58c0-0.01,0-0.01,0-0.02c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0c0,0,0,0,0,0
			C14.94,5.56,14.94,5.57,14.93,5.58z"
              />
              <Path
                fill={color}
                d="M14.97,5.48c-0.01,0-0.01,0-0.02,0c0-0.01,0-0.01,0-0.02l0,0c0.01,0,0.01,0,0.02,0
			C14.97,5.47,14.97,5.47,14.97,5.48z"
              />
              <Path
                fill={color}
                d="M14.97,5.52c-0.01,0-0.01,0-0.02,0c0,0,0,0,0,0c0-0.01,0-0.01,0-0.02l0,0c0.01,0,0.01,0,0.02,0
			C14.97,5.51,14.97,5.51,14.97,5.52z"
              />
              <Path
                fill={color}
                d="M14.93,5.46c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0l0,0c0-0.01,0-0.01,0-0.02
			C14.92,5.46,14.92,5.46,14.93,5.46z"
              />
              <Path
                fill={color}
                d="M14.93,5.48c0.01,0,0.01,0,0.02,0c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0
			C14.93,5.49,14.93,5.49,14.93,5.48L14.93,5.48z"
              />
              <Path
                fill={color}
                d="M14.93,5.54c0-0.01,0-0.01,0-0.02c0.01,0,0.01,0,0.02,0c0,0,0,0,0,0c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0
			C14.94,5.54,14.94,5.54,14.93,5.54L14.93,5.54z"
              />
              <Path
                fill={color}
                d="M14.91,5.54c0.01,0,0.01,0,0.02,0c0,0,0,0,0,0c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0
			C14.91,5.55,14.91,5.54,14.91,5.54z"
              />
              <Path
                fill={color}
                d="M14.91,5.5c-0.01,0-0.01,0-0.02,0c0-0.01,0-0.01,0-0.02c0.01,0,0.01,0,0.02,0c0,0,0,0,0,0
			C14.91,5.49,14.91,5.49,14.91,5.5z"
              />
              <Path
                fill={color}
                d="M14.91,5.5c0.01,0,0.01,0,0.02,0c0,0.01,0,0.01,0,0.02c-0.01,0-0.01,0-0.02,0
			C14.91,5.51,14.91,5.51,14.91,5.5z"
              />
            </G>
          </Svg>
        </View>
        <Text style={styles.adress}>{data.adress}</Text>
        <Text style={styles.description}>{data.description}</Text>

        <View style={styles.flex}>
          <Text>{data.season}</Text>
          <Text style={{ textTransform: 'uppercase' }}>{data.service}</Text>
        </View>
        <View style={styles.line} />
      </View>
    </>
  )
}

// @ts-nocheck
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { Menu } from 'type'

interface Props {
  data: Menu
  color?: string
  fontSizePercentage?: number
}

export const Header: React.FC<Props> = ({ data, color, fontSizePercentage }) => {
  const FONT_SIZE_RATIO = fontSizePercentage || 100
  const adjustFontSize = (size) => `${(size * FONT_SIZE_RATIO) / 100}pt`
  const styles = StyleSheet.create({
    section: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      marginBottom: '12px'
    },
    subsection: {
      margin: '20px 0px'
    },
    flex: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center'
    },
    line: {
      borderBottom: `1px solid ${color}`,
      width: '100%'
    },
    permanent: {
      textDecorationColor: `${color}`,
      textTransform: 'uppercase',
      fontSize: adjustFontSize(6),
      fontFamily: 'saa-f-d',
      fontWeight: 'bold',
      textAlign: 'center',
      letterSpacing: '1px'
    },
    adress: {
      textDecorationColor: `${color}`,
      textTransform: 'uppercase',
      fontSize: adjustFontSize(7),
      fontFamily: 'saa-f-d',
      fontWeight: 'bold',
      textAlign: 'center',
      letterSpacing: '1px'
    },
    title: {
      fontFamily: 'brioche',
      fontSize: adjustFontSize(28),
      textTransform: 'uppercase',
      textDecorationColor: `${color}`,
      textAlign: 'center'
    },

    description: {
      fontFamily: 'minion',
      textTransform: 'uppercase',
      fontSize: adjustFontSize(7),
      marginTop: '13px',
      textAlign: 'center',
      width: '100%',
      letterSpacing: '2px'
    },
    logo: {
      width: '35px',
      position: 'absolute',
      right: '5px',
      top: '0'
    },
    block: {
      width: '33%'
    }
  })
  const Br = () => '\n'
  return (
    <>
      <View style={styles.section}>
        <View style={styles.subsection}>
          <View style={styles.flex}>
            <View style={styles.block}>
              <Text style={styles.permanent}>
                Tutto è cucinato nella più pura tradizione italiana
                <Br /> Everything cooked in classic Italian tradition
              </Text>
            </View>
            <View style={styles.block}>
              <Text style={styles.title}>{data.label}</Text>
            </View>
            <View style={styles.block}>
              <Text style={styles.adress}>{data.adress}</Text>
            </View>
          </View>
        </View>
        <View style={styles.line} />
        <View
          style={{
            ...styles.line,
            marginTop: '1px'
          }}
        />
        <View
          style={{
            ...styles.line,
            marginTop: '1px'
          }}
        />
        <View style={styles.description}>
          <Text>{data.description}</Text>
        </View>
      </View>
    </>
  )
}

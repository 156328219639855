// @ts-nocheck
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { Menu } from 'type'

interface Props {
  data: Menu
  color?: string
  fontSizePercentage?: number
}

export const PricePhilosohpy: React.FC<Props> = ({ data, color, fontSizePercentage }) => {
  const FONT_SIZE_RATIO = fontSizePercentage || 100
  const adjustFontSize = (size) => `${(size * FONT_SIZE_RATIO) / 100}pt`
  const styles = StyleSheet.create({
    section: {
      margin: '0 10px',
      padding: '10px 30px',
      textAlign: 'center',
      border: `1px solid ${color}}`,
      color
    },
    title: {
      textTransform: 'uppercase',
      fontFamily: 'saa',
      fontSize: adjustFontSize(12),
      marginBottom: '10px'
    }
  })
  if (!data.pricePhilosophy?.label && !data.pricePhilosophy?.description) {
    return <></>
  }
  return (
    <>
      <View style={styles.section}>
        <Text style={styles.title}>{data.pricePhilosophy?.label}</Text>
        <Text>{data.pricePhilosophy?.description}</Text>
      </View>
    </>
  )
}

import { BadgeCheckIcon } from '@heroicons/react/solid'
import { useHideProduct } from 'api/hooks/useHideProduct'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

interface Props {
  productId?: string | null | undefined
  pairingProduct: Record<string, any>
  categoryId?: string | null | undefined
  relationalProductId?: string | null | undefined
  description?: string | null | undefined
  title?: string | null | undefined
  isHidden?: boolean | null | undefined
  isPairingHidden?: boolean | null | undefined
  isUnavailable?: boolean | null | undefined
  setFreeze: (bool: boolean) => void
  refetchHiddenProducts: () => void
  freeze: boolean
  pairingProductId?: string | null | undefined
}

export const Product: React.FC<Props> = ({
  title,
  isHidden,
  isPairingHidden,
  isUnavailable,
  productId,
  pairingProduct,
  relationalProductId,
  categoryId,
  description,
  setFreeze,
  freeze,
  refetchHiddenProducts,
  pairingProductId
}) => {
  const [hidden, setHidden] = useState(isHidden)
  const [pairingHidden, setPairingHidden] = useState(isPairingHidden)
  const { slug } = useParams<{ slug: string }>()

  const { mutate } = useHideProduct({
    product_id: pairingProduct?.length ? pairingProductId : productId,
    slug_menu: slug
  })

  const handleClick = (type: string) => {
    setFreeze(true)
    if (type === 'product' && productId && categoryId && relationalProductId && slug) {
      setHidden(!hidden)
      mutate({
        product_id: productId,
        slug_menu: slug
      }).then(() => {
        setFreeze(false)
        refetchHiddenProducts()
        // handleRefetch();
      })
    }
    if (type === 'pairingProduct' && pairingProductId && slug) {
      setPairingHidden(!pairingHidden)
      mutate({
        product_id: pairingProductId,
        slug_menu: slug
      }).then(() => {
        setFreeze(false)
        refetchHiddenProducts()
        // handleRefetch();
      })
    }
  }

  return (
    <div className={`bg-white shadow rounded-lg mb-4 font-sans ${freeze ? 'opacity-50' : ''}`}>
      <div className="px-4 py-5 sm:p-6">
        <div className="flex items-center">
          <div>
            <BadgeCheckIcon className={`w-5 h-5 ${hidden ? 'text-red-400' : 'text-green-400'} mr-5`} />
          </div>
          <div className="">
            <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
          </div>
        </div>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl text-[12px] font-mono text-gray-500">
            <p className="mb-2">{description}</p>
            <span className="text-xs mt-4 border rounded-full px-2 text-gray-400 border-gray-400">
              id: {productId}
              {isUnavailable ? ' (!)' : ''}
            </span>
          </div>
          <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
            <button
              disabled={freeze}
              onClick={() => handleClick('product')}
              type="button"
              className={`w-full justify-center inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white ${
                !hidden ? 'bg-green-400 hover:bg-green-700 ' : 'bg-red-500 hover:bg-red-600 '
              } focus:outline-none  sm:text-sm`}
            >
              {hidden ? 'THIS PRODUCT IS OFFLINE' : 'THIS PRODUCT IS ONLINE'}
            </button>
          </div>
        </div>
      </div>
      {pairingProduct.length ? (
        <div className="px-4 py-5 sm:p-6">
          <div className="text-s italic text-cur mt-4 rounded-full text-gray-400 pb-2">Produit associé:</div>
          <div className="flex items-center">
            <div>
              <BadgeCheckIcon className={`w-5 h-5 ${pairingHidden ? 'text-red-400' : 'text-green-400'} mr-5`} />
            </div>
            <div className="">
              <h3 className="text-lg leading-6 font-medium text-gray-900">{pairingProduct[0].title}</h3>
            </div>
          </div>
          <div className="mt-2 sm:flex sm:items-start sm:justify-between">
            <div className="max-w-xl text-[12px] font-mono text-gray-500">
              <p className="mb-2">{pairingProduct[0].description}</p>
              <span className="text-xs mt-4 border rounded-full px-2 text-gray-400 border-gray-400">
                id: {pairingProductId}
                {/* {isUnavailable ? ' (!)' : ''} */}
              </span>
            </div>
            <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
              <button
                disabled={freeze}
                onClick={() => handleClick('pairingProduct')}
                type="button"
                className={`w-full justify-center inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white ${
                  !pairingHidden ? 'bg-green-400 hover:bg-green-700 ' : 'bg-red-500 hover:bg-red-600 '
                } focus:outline-none  sm:text-sm`}
              >
                {pairingHidden ? 'THIS PRODUCT IS OFFLINE' : 'THIS PRODUCT IS ONLINE'}
              </button>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

import { Layout } from 'components/render/layout'
import { Preview } from 'components/render/v2/preview'

export const RenderPdfV2 = () => {
  return (
    <Layout withOptions>
      <Preview />
    </Layout>
  )
}

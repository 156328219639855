import { useEffect, useState } from 'react'
import splashLogo from '../../../assets/splash-screen-icon.png'

export const SplashScreen = ({
  color,
  restaurantName,
  restaurantAdress,
  imgUrl
}: {
  color?: string
  restaurantName?: string | null | undefined
  restaurantAdress?: string | null | undefined
  imgUrl?: string | null | undefined
}) => {
  const backgroundColor = color?.startsWith('#') ? color : `#${color}`
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1)
    }, 50)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div
      style={{ background: backgroundColor }}
      className={`flex flex-col items-center justify-between w-full h-[100dvh] px-5 py-6 bg-primary transition-opacity duration-1000 ease-in ${opacity ? 'opacity-100' : 'opacity-0'}`}
    >
      <div />
      <img className="w-52" src={imgUrl || splashLogo} alt="splash logo" />

      <div className="flex flex-col self-center justify-center text-xs text-white font-prestige w-96">
        {restaurantName && <p className="mx-auto text-center">{restaurantName}</p>}
        {restaurantAdress && <p className="mx-auto text-center">{restaurantAdress}</p>}
      </div>
    </div>
  )
}

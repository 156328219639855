import { useMenuList } from 'api/hooks/useMenuList'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export const RestaurantList = () => {
  const [locale, setLocale] = useState(navigator.language)

  const { data, isFetched } = useMenuList(locale)

  useEffect(() => {
    // Set locale to "en" if no data !
    if (data?.length === 0 && isFetched) {
      setLocale('en')
    }
  }, [isFetched, data])

  if (!data) {
    return <p className="h-screen flex itemse-center justify-center "></p>
  }

  return (
    <>
      <div className="flex justify-center items-center h-screen w-full bg-secondary">
        <div>
          {data.map((res) => {
            return (
              <p
                className={`md:text-[70px] text-[50px] font-black text-center hover:text-[#e5006c] transition-all duration-200 hover:rotate-6`}
                key={res?.slug}
              >
                <Link to={`/${res?.slug}`}>{res?.title}</Link>
              </p>
            )
          })}
        </div>
      </div>
    </>
  )
}

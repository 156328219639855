import { useGetAllProductsByMenu } from 'api/hooks/useGetAllProductsByMenu'
import { NoResult } from 'components/menus/admin/no-result'
import { Product } from 'components/menus/admin/product'
import { Search } from 'components/menus/admin/search'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useHiddenProducts } from 'api/hooks/useHiddenProducts'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'

export interface Products {
  product_id?: string | null | undefined
  category_id?: string | null | undefined
  relational_product_id?: string | null | undefined
  description?: string | null | undefined
  title?: string | null | undefined
  is_unavailable?: boolean | null | undefined
}

export const RestaurantAdmin = () => {
  const [search, setSearch] = useState('')
  const [freeze, setFreeze] = useState(false)
  const [showOfflineFirst, setShowOfflineFirst] = useState(false)

  const [products, setProducts] = useState<any>()
  const [searchParams] = useSearchParams()
  const password = searchParams.get('password')
  const { data, productsIsFetched, errors, isError, dataUpdatedAt, refetch } = useGetAllProductsByMenu(password)

  const { hidden_products } = useHiddenProducts()

  const { refetch: refetchHiddenProducts } = useHiddenProducts()

  useEffect(() => {
    let filteredProducts = data?.products?.filter((p) => p?.title?.toLowerCase().includes(search.toLowerCase())) ?? []

    if (showOfflineFirst && filteredProducts) {
      filteredProducts = filteredProducts.filter(
        (p) => p && p.product_id && hidden_products.includes(parseInt(p.product_id))
      )
    }

    setProducts(filteredProducts)
  }, [search, data, dataUpdatedAt, showOfflineFirst, hidden_products])

  if (!productsIsFetched) {
    return <div className="h-screen">Loading...</div>
  }
  if (isError) {
    return (
      <div className="h-screen">
        {errors.map((m: string) => (
          <p>{m}</p>
        ))}
      </div>
    )
  }

  return (
    <>
      <div className="bg-gray-100 overflow-x-hidden h-screen ">
        <div className="flex justify-center  h-full w-full  container max-w-[768px]  mx-auto">
          <div className="w-full p-5">
            <div className="flex items-center w-full justify-between -mt-2">
              <h1 className="text-[20px] text-right  text-gray-800">{data?.title}</h1>
              <p>App v-0.3</p>
            </div>

            <div className="mb-4">
              <Search setQuery={(query) => setSearch(query)} />
            </div>
            {products?.length === 0 && <NoResult />}
            {products?.length > 0 && (
              <div>
                <button
                  className={`justify-center inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white 
                    ${showOfflineFirst ? 'bg-red-500 hover:bg-red-600 ' : 'bg-gray-400 hover:bg-gray-500 '}`}
                  onClick={() => {
                    setShowOfflineFirst((prev) => !prev)
                    refetch()
                    refetchHiddenProducts()
                  }}
                >
                  {showOfflineFirst ? (
                    <CheckCircleIcon className="h-6 w-6 inline mr-2" />
                  ) : (
                    <XCircleIcon className="h-6 w-6 inline mr-2" />
                  )}
                  Only offline products
                </button>
                <p className="mb-2 text-right text-gray-600 text-[13px]">
                  {products?.length} product{products?.length > 1 && 's'}
                </p>
              </div>
            )}

            {products?.map((p: any, idx: number) => {
              // generate unique id for each product
              const id = `${p?.product_id}-${idx}`

              return (
                <Product
                  setFreeze={(bool) => {
                    setFreeze(bool)
                  }}
                  freeze={freeze}
                  title={p.title}
                  description={p.description}
                  productId={p.product_id}
                  pairingProductId={p?.pairing_product_id}
                  pairingProduct={p.pairing_product}
                  categoryId={p.category_id}
                  relationalProductId={p.relational_product_id}
                  key={id}
                  isHidden={hidden_products?.includes(parseInt(p.product_id))}
                  isPairingHidden={hidden_products?.includes(parseInt(p.pairing_product_id))}
                  isUnavailable={p.is_unavailable}
                  refetchHiddenProducts={refetchHiddenProducts}
                />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

type FilterButtonPropsType = {
  onClick: () => void
  title: string
  isActive: boolean
  backgroundColor?: string | null
}

export const FilterModalButton = ({ onClick, title, isActive, backgroundColor }: FilterButtonPropsType) => {
  return (
    <button
      onClick={onClick}
      style={{ color: isActive ? backgroundColor ?? '' : 'white' }}
      className={`border-[1px] tracking-wider border-white px-5 py-2 uppercase font-saaFD text-[13px] ${isActive && 'bg-white text-primary'}`}
    >
      {title}
    </button>
  )
}
